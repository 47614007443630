const Footer = () => {
  return (
    <div>
      <footer className="footer text-center">
        <div className="container px-4 px-lg-5">
          <ul className="list-inline mb-5">
{/*
            <li className="list-inline-item">
              <a className="" href="/agb">
                AGBs
              </a>
  </li>*/}
            <li className="list-inline-item">
              <a className="" href="/datenschutz">
                Datenschutz
              </a>
            </li>
            <li className="list-inline-item">
              <a className="" href="/impressum">
                Impressum
              </a>
            </li>
            <li className="list-inline-item">
              <a className="" href="/haftungsausschluss">
                Haftungsausschluss
              </a>
            </li>
          </ul>
          <p className="text-muted small mb-0">
            Copyright &copy; Travelrights 2022
          </p>
        </div>
      </footer>

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </div>
  );
};

export default Footer;
