import { Container, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const Onsite = () => {
  return (
    <Container className="p-0" fluid>
      <Helmet>
        <title>Ärger im Pauschalurlaub - Vor-Ort Ratgeber</title>
        <meta
          name="description"
          content="Zusammenfassung der nötigen Vor-Ort Maßnahmen, um die Erstattung Ihrer Schadensersatzansprüche zu sichern!"
        />
      </Helmet>
      <div className="simple_header p-4">
        <Row>
          <Col xl="12" className="d-flex justify-content-center">
            <h1 className="mb-4 text-center">
              Ärger im Pauschalurlaub - Was muss ich vor Ort tun?
            </h1>
          </Col>{" "}
        </Row>
        <Row>
          {" "}
          <Col xl="12" className="d-flex justify-content-center">
            <h2 className="text-dark mb-0 text-center">Der Vor-Ort-Ratgeber</h2>
          </Col>
        </Row>
        <Row>
          <Col xl="12" className="d-flex justify-content-center">
            {" "}
            <p className="mt-4">
              Sichern und maximieren Sie Ihre Schadensersatzansprüche, indem Sie
              vor Ort folgende Maßnahmen ergreifen:{" "}
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center" xs={1} md={2} lg={3} xl={3} xxl={5}>
          <Col  className="d-flex justify-content-center mb-4">
            {" "}
            <Card className="onsite_card">
              <Card.Body>
                <Card.Title>
                  <p className="large_number">01</p>
                  <h2>Zeigen Sie den Mangel unverzüglich an</h2>
                </Card.Title>
                <Card.Text>
                  <p>
                    Legen Sie bei der Reiseleitung, dem Reisebüro oder dem
                    Reiseveranstalter Beschwerde ein und teilen Sie klar den
                    erlittenen Mangel mit! Wichtig: Eine Anzeige gegenüber dem
                    Hotel reicht leider nicht.
                  </p>
                  <p>
                    Tipp: Nehmen Sie zur Mängelanzeige Zeugen mit.
                    Möglicherweise gibt es weitere Gäste, die die entsprechenden
                    Mängel zu beklagen haben.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col  className="d-flex justify-content-center mb-4">
            {" "}
            <Card className="onsite_card">
              <Card.Body>
                <Card.Title>
                  <p className="large_number">02</p>
                  <h2>
                    {" "}
                    Bestehen Sie auf einer schriftlichen Bestätigung der der
                    Mängelanzeige (siehe{" "}
                    <a
                      href="https://www.gesetze-im-internet.de/bgb/__651o.html"
                      className="link_no_underlining"
                      style={{ color: "#fecc4398" }}
                    >
                      BGB §651 O
                    </a>
                    )
                  </h2>
                </Card.Title>
                <Card.Text>
                  {" "}
                  <p>
                    Tipp: Falls dieser Nachweis der Mängelanzeige im Nachhinein
                    nicht vorliegt, reichen auch Zeugen, die im Falle eines
                    Prozesses die Anzeige bestätigen können.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col  className="d-flex justify-content-center mb-4">
            {" "}
            <Card className="onsite_card">
              <Card.Body>
                <Card.Title>
                  <p className="large_number">03</p>
                  <h2>
                    Abhilfeverlangen: Fordern Sie explizit zur Mängelbeseitigung
                    auf
                  </h2>
                </Card.Title>
                <Card.Text>
                  {" "}
                  <p>
                    Dokumentieren Sie, wann sie die Beseitigung welcher Mängel
                    gefordert haben und ob keine oder nur bedingte Abhilfe
                    geschaffen wurde.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col  className="d-flex justify-content-center mb-4">
            <Card className="onsite_card">
              <Card.Body>
                <Card.Title>
                  <p className="large_number">04</p>
                  <h2>Sichern Sie Beweise und führen Sie Protokoll</h2>
                </Card.Title>
                <Card.Text>
                  {" "}
                  <p>
                    Sammeln Sie nach Möglichkeit von allen Mängeln Beweise.
                    Machen Sie Fotos und Notizen als Gedächtnisstütze.
                  </p>
                  <p>
                    Tipp: Führen Sie ein detailliertes Protokoll über Art,
                    Zeitpunkt und Dauer von Mängeln (z.B. ein Lärmprotokoll).
                    Darin können Sie ebenso Mängel-Anzeigen,
                    erfolgte/fehlgeschlagene Maßnahmen zur Mängelbeseitigung
                    sowie Kontaktdaten von Zeugen erfassen.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col  className="d-flex justify-content-center mb-4">
            {" "}
            <Card className="onsite_card">
              <Card.Body>
                <Card.Title>
                  <p className="large_number">05</p>
                  <h2>
                    Verlieren Sie keine Zeit und machen Sie ihre Ansprüche zügig
                    nach Ihrer Rückkehr geltend.
                  </h2>
                </Card.Title>
                <Card.Text>
                  {" "}
                  <p>
                    Stellen Sie sicher, dass Sie keine relevanten Fristen
                    versäumen und dass Ihre Ansprüche nicht verjähren, indem Sie
                    sie umgehend nach Ihrer Rückreise geltend machen. Unsere
                    Experten (Partneranwälte) unterstützen Sie kompetent dabei.
                    Haben Sie alle Punkte der Checkliste beachtet? Ermitteln Sie
                    die Höhe der möglicherweise zu erwartenden
                    Reisepreisminderung mit unserem
                    <a href="/online-rechner" className="link_no_underlining" style={{ color: "#fecc4398" }}>
                      {" "}
                      Reisepreisminderungsrechner.
                    </a>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <p className="mt-3">
          <a className="btn btn-dark" href="/">
            Zurück
          </a>
        </p>
      </div>
    </Container>
  );
};

export default Onsite;
