import { Container } from "react-bootstrap";

const Liability = () => {
  return (
    <Container fluid className="m-0 p-0">
      <div className="simple_header m-0 p-4 text-start">
        <h1>Haftungsausschluss - Keine Rechtsberatung</h1>
        <p className="mt-4">
          Wir erstellen unsere Artikel mit größter Sorgfalt und bieten
          umfassende Informationen rund um den Rechtsbereich "Reiserecht" .
          Dabei ist es uns wichtig, unsere Inhalte leicht verständlich
          aufzubereiten.
        </p>
        <p>
          <strong>
            Dennoch geben die Informationen auf travelrights.de nur eine erste
            Darstellung der Rechtslage wieder. Sie decken aber ggf.
            Besonderheiten des Einzelfalles nicht ab. <br/>Die Informationen in
            unseren Artikeln können und sollen eine Rechtsberatung im Einzelfall
            nicht ersetzen.
          </strong>
        </p>
        <p>
          <a className="btn btn-dark" href="/">
            Zurück
          </a>
        </p>
      </div>
    </Container>
  );
};

export default Liability;
