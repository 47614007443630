import { Container } from "react-bootstrap";

const Imprint = () => {
  return (
    <Container fluid className="m-0 p-0">
      <div className="simple_header m-0 p-4 text-start">
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Muster GmbH
          <br />
          Wiesenstra&szlig;e 17a
          <br />
          85368 Moosburg an der Isar
        </p>

        <p>
          Handelsregister: HRB 999999
          <br />
          Registergericht: Amtsgericht Musterstadt
        </p>

        <p>
          <strong>Vertreten durch:</strong>
          <br />
          Christoph H&uuml;bner
          <br />
          Dr. Andreas Tomandl
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: +49 (0) 89 38 15 14 38
          <br />
          E-Mail: info@musterfirma.de
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
          Umsatzsteuergesetz:
          <br />
          DE999999999
        </p>

        <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
        <p>
          <strong>Name und Sitz des Versicherers:</strong>
          <br />
          Beispiel Versicherung AG
          <br />
          Musterweg 10
          <br />
          90210 Musterstadt
        </p>
        <p>
          <strong>Geltungsraum der Versicherung:</strong>
          <br />
          Deutschland
        </p>

        <h2>Redaktionell verantwortlich</h2>
        <p>
          Muster GmbH
          <br />
          Wiesenstra&szlig;e 17a
          <br />
          85368 Moosburg an der Isar
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <p>
          Quelle:{" "}
          <a href="https://www.e-recht24.de/impressum-generator.html">
            https://www.e-recht24.de/impressum-generator.html
          </a>
        </p>
        <p>
          <a className="btn btn-dark" href="/">
            Zurück
          </a>
        </p>
      </div>
    </Container>
  );
};

export default Imprint;
