const Header = () => {
  return (
    <div>
      <a className="menu-toggle rounded" href="/#">
        <i className="fas fa-bars"></i>
      </a>
      <nav id="sidebar-wrapper">
        <ul className="sidebar-nav">
          <li className="sidebar-brand">
            <a href="/#">
              <img
                className="w-75 text-start pe-2"
                src="../assets/img/logo3.png"
                alt="Travelrights"
              />
            </a>
          </li>
          <li className="sidebar-nav-item">
            <a href="/#">Home</a>
          </li>
          <li className="sidebar-nav-item">
            <a href="/online-rechner">Online Rechner</a>
          </li>
          <li className="sidebar-nav-item">
            <a href="/massnahmen-vor-ort">Maßnahmen vor Ort</a>
          </li>
          <li className="sidebar-nav-item">
            <a href="/frankfurter-tabelle">Frankfurter Tabelle</a>
          </li>
          <li className="sidebar-nav-item">
            <a href="/aktuelles-reiserecht-in-der-gesetzgebung">
              Aktuelles Reiserecht
            </a>
          </li>
          <li className="sidebar-nav-item">
            <a href="/datenschutz">Datenschutz</a>
          </li>
          <li className="sidebar-nav-item">
            <a href="/impressum">Impressum</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
