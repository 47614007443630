import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const FrankfurterTabelle = () => {
  return (
    <Container className="p-0" fluid>
      <Helmet>
        <title>Frankfurter Tabelle 2022 samt Rechner</title>
        <meta
          name="description"
          content="✓die aktuellste Version der Frankfurter Tabelle ✓inkl. Ergänzungen"
        />
      </Helmet>
      <div className="container px-4 px-lg-5 text-start">
        <h1>
          Frankfurter Tabelle (Basistabelle von 1985 mit den Ergänzungen von
          1994)
        </h1>

        <p>
          Hinweis: Die Reform des Reiserechts im BGB von 2018 ist in der
          Frankfurter Tabelle nicht berücksichtig, d.h. die Verweise auf das BGB
          wurden nicht nachgezogen.
        </p>

        <h2>Teil I: Mängel der Unterkunft im Reiserecht</h2>

        <ol start="1" type="1">
          <li>
            Abweichung von dem gebuchten Objekt: 10-25% (je nach Entfernung)
          </li>
          <li>Abweichende örtliche Lage (Strandentfernung): 5-15%</li>
          <li>
            Abweichende Art der Unterbringung im gebuchten Hotel (Hotel statt
            Bungalow, abweichendes Stockwerk): 5-10%
          </li>
          <li>
            Abweichende Art der Zimmer <br />
            a) DZ statt EZ: 20% <br />
            b) DreibettZ statt EZ: 25% <br />
            c) DreibettZ statt DZ: 20-25% <br />
            d) VierbettZ stattDZ: 20-30% (zu c und d: Entscheidend, ob Personen
            der gleichen Buchung oder unbekannte Reisende zusammengelegt werden)
          </li>
          <li>
            Mängel in der Ausstattung des Zimmers <br />
            a) zu kleine Fläche: 5-10% <br />
            b) fehlender Balkon: 5-10% (bei Zusage/je nach Jahreszeit) <br />
            c) fehlender Meerblick: 5-10% (bei Zusage) <br />
            d) fehlendes (eigenes) Bad/WC: 15-25% (bei Buchung) <br />
            e) fehlendes eigenes WC: 15% <br />
            f) fehlende (eigene) Dusche: 10% (bei Buchung) <br />
            g) fehlende Klimaanlage: 10-20% (bei Zusage/je nach Jahreszeit){" "}
            <br />
            h) fehlendes Radio/TV: 5% (bei Zusage) <br />
            i) zu geringes Mobiliar: 5-15% <br />
            k) Schäden (Risse, Feuchtigkeit etc.): 10-50% <br />
            l) Ungeziefer: 10-50%
          </li>
          <li>
            Ausfall von Versorgungseinrichtungen <br />
            a) Toilette: 15% <br />
            b) Bad/Warmwasserboiler: 15% <br />
            c) Stromausfall/Gasausfall: 10-20% <br />
            d) Wasser: 10% <br />
            e) Klimaanlage: 10-20% (je nach Jahreszeit) <br />
            f) Fahrstuhl: 5-10% (je nach Stockwerk)
          </li>
          <li>
            Service <br />
            a) vollkommener Ausfall: 25% <br />
            b) schlechte Reinigung: 10-20% <br />
            c) ungenügender Wäschewechsel (Bettwäsche, Handtücher): 5-10%
          </li>
          <li>
            Beeinträchtigungen <br />
            a) Lärm am Tage: 5-25% <br />
            b) Lärm in der Nacht: 10-40% <br />
            c) Gerüche: 5-15%
          </li>
          <li>
            Fehlen der (zugesagten) Kureinrichtungen (Thermalbad, Massagen):
            20-40% (je nach Art der Projektzusage, z.B. "Kururlaub2)
          </li>
        </ol>

        <h2>Teil II: Mängel bei der Verpflegung</h2>

        <ol start="1" type="1">
          <li>Vollkommener Ausfall: 50%</li>
          <li>
            Inhaltliche Mängel <br />
            a) Eintöniger Speisenzettel: 5% <br />
            b) Nicht genügend warme Speisen: 10% <br />
            c) Verdorbene (ungenießbare) Speisen: 20-30%
          </li>
          <li>
            Service <br />
            a) Selbstbedienung (statt Kellner): 10-15% <br />
            b) lange Wartezeiten: 5-15% <br />
            c) Essen in Schichten: 10% <br />
            d) Verschmutzte Tische: 5-10% <br />
            e) Verschmutztes Geschirr, Besteck: 10-15%
          </li>
          <li>Fehlende Klimaanlage im Speisesaal: 5-10% (bei Zusage)</li>
        </ol>

        <h2>Teil III: Sonstige Mängel im Reiserecht</h2>

        <ol start="1" type="1">
          <li>
            Fehlender oder verschmutzter Swimmingpool: 10-20% (bei Zusage)
          </li>
          <li>
            Fehlendes Hallenbad <br />
            a) bei vorhandenem Swimmingpool: 10% (soweit nach Jahreszeit
            benutzbar) <br />
            b) bei nicht vorhandenem Swimmingpool: 20%
          </li>
          <li>Fehlende Sauna: 5% (bei Zusage)</li>
          <li>Fehlender Tennisplatz: 5-10% (bei Zusage)</li>
          <li>Fehlendes Mini-Golf: 3-5% (bei Zusage)</li>
          <li>
            Fehlende Segelschule, Surfschule, Tauchschule: 5-10% (bei Zusage)
          </li>
          <li>Fehlende Möglichkeit zum Reiten: 5-10%(bei Zusage)</li>
          <li>Fehlende Kinderbetreuung: 5-10% (bei Zusage)</li>
          <li>
            Unmöglichkeit des Badens im Meer: 10-20% (je nach
            Prospektbeschreibung und zumutbarer Ausweichmöglichkeit)
          </li>
          <li>Verschmutzter Strand: 10-20%</li>
          <li>Fehlende Strandliegen, Sonnenschirme: 5-10% (bei Zusage)</li>
          <li>
            Fehlende Snack- oder Strandbar: 0-5% (je nach Ersatzmöglichkeit)
          </li>
          <li>Fehlender FKK-Strand: 10-20% (bei Zusage)</li>
          <li>
            Fehlendes Restaurant oder Supermarkt (bei Zusage/je nach
            Ausweichmöglichkeit) <br />
            a) bei Hotelverpflegung: 0-5% <br />
            b) bei Selbstverpflegung: 10-20%
          </li>
          <li>
            Fehlende Vergnügungseinrichtungen (Disco, Nightclub, Kino,
            Animateure): 5-15% (bei Zusage)
          </li>
          <li>
            Fehlende Boutique oder Ladenstraße: 0-5% (je nach
            Ausweichmöglichkeit)
          </li>
          <li>
            Ausfall von Landausflügen bei Kreuzfahrten: 20-30% (des anteiligen
            Reisepreises je Tag des Landausflugs)
          </li>
          <li>
            Fehlende Reiseleitung <br />
            a) bloße Organisation: 0-5% <br />
            b) bei Besichtigungsreisen: 10-20% <br />
            c) bei Studienreisen mit wissenschaftlicher Führung: 20-30% (bei
            Zusage)
          </li>
          <li>
            Zeitverlust durch notwendigen Umzug <br />
            a) im gleichen Hotel: anteiliger Reisepreis für halben Tag <br />
            b) in anderes Hotel: anteiliger Reisepreis für ganzen Tag
          </li>
        </ol>

        <h2>Teil IV: Mängel beim Transport</h2>

        <ol start="1" type="1">
          <li>
            Zeitlich verschobener Abflug über 4 Stunden hinaus: 5% (des
            anteiligen Reisepreises für einen Tag für jede weitere Stunde)
          </li>
          <li>
            AusstattungsMängel <br />
            a) Niedrigere Klasse: 10-15% <br />
            b) Erhebliche Abweichung vom normalen Standard: 5-10%
          </li>
          <li>
            Service <br />
            a) Verpflegung: 5% <br />
            b) Fehlen der in der Flugklasse üblichen Unterhaltung (Radio, Film,
            etc.): 5%
          </li>
          <li>
            Auswechslung des Transportmittels: Der auf die Transportverzögerung
            entfallende anteilige Reisepreis
          </li>
          <li>
            Fehlender Transfer vom Flugplatz (Bahnhof) zum Hotel: Kosten des
            Ersatztransportmittels
          </li>
        </ol>

        <h2>Erläuterungen zur Frankfurter Tabelle</h2>

        <h3>Hinweis: Änderungen von 1994 sind in Kursiv markiert</h3>
        <ol>
          <li>Geringfügige Beeinträchtigungen bleiben außer Betracht.</li>
          <li>
            <p>
              Die Höhe des Prozentsatzes richtet sich bei Rahmensätzen nach der
              Intensität der Beeinträchtigung. Diese ist in der Regel unabhängig
              von den Eigenschaften des einzelnen Reisenden (Alter, Geschlecht,
              besondere Empfindlichkeit, besondere Unempfindlichkeit).
            </p>
            <p>Ausnahmen:</p>
            <ol type="a">
              <li>
                Bei besonderen Eigenschaften oder Gebrechen eines Reisenden, die
                dem Reiseveranstalter bei Buchung bekannt waren, kann bei
                besonders erheblicher Beeinträchtigung der einzelne Tabellensatz
                und der Höchstprozentsatz um 50 % erhöht werden.
              </li>
              <li>
                Bei Mängeln der Gruppe III unterbleibt eine Minderung, wenn eine
                Beeinträchtigung für den Reisenden offenkundig oder nachweisbar
                nicht gegeben war.
              </li>
            </ol>
          </li>
          <li>
            <p>
              Im Rahmen dieser Ziff. 6a-c bleiben die in Ziff.4b-d vorgesehene
              Erhöhung und Verminderung der Prozentsätze außer Betracht.
            </p>
            <p>
              <ol type="a">
                <li>
                  Soweit Beeinträchtigungen während der Reisedauer nur
                  zeitweilig auftreten, wird für die Minderung der auf die
                  entsprechende Zeit umgelegte Gesamtreisepreis der Minderung
                  zugrunde gelegt. Gleiches gilt, wenn die Gewährleistung des
                  Reiseveranstalters wegen schuldhaft unterlassener Anzeige des
                  Mangels (§651e651d II BGB) oder wegen Nichtannahme eines
                  zumutbaren Ersatzangebotes entfällt.
                </li>
                <li>
                  In Ausnahmefällen (kleinere Mängel bis Höchstens 10%) kann der
                  Prozentsatz dem (anteiligen) Aufenthaltspreis entnommen
                  werden, wenn durch die Mängel der Gesamtzuschnitt der Reise
                  nicht wesentlich verändert worden ist.
                </li>
                <li>
                  Bei zusammengesetzten Reisen (z.B. Rundreise mit
                  anschließendem Hotelaufenthalt) ist die Minderung aus dem
                  Gesamtreisepreis zu berechnen. Soweit einzelne Reiseteile
                  betroffen sind, ist Nr.3a entsprechend anzuwenden.
                </li>
              </ol>
            </p>
          </li>
          <li>
            Bei Vorliegen mehrerer Mängelpositionen werden die Prozentsätze
            addiert.
            <ol type="a">
              <li>
                <p>
                  Ist Gegenstand des Vertrages die Leistung von{" "}
                  <b>Unterkunft und Vollpension</b>, so dürfen folgende
                  Gesamtprozentsätze innerhalb einer Leistungsgruppe nicht
                  überschritten werden:
                </p>
                <p>
                  Gruppe I (Unterkunft): 50%
                  <br />
                  Gruppe II (Verpflegung): 50%
                  <br />
                  Gruppe III (Sonstiges): 30%
                  <br />
                  Gruppe IV (Transport): 20%
                </p>
              </li>
              <li>
                <p>
                  Ist Gegenstand des Vertrages die Leistung von{" "}
                  <b>Unterkunft und Halbpension</b>, erhöhen sich die
                  Tabellensätze der Gruppe I (mit Ausnahme von Position I/1) 1/4
                  = 25% und vermindern sich die Tabellensätze der Gruppe II um
                  1/4 = 25%. Dabei dürfen folgende Gesamtprozentsätze innerhalb
                  einer Leistungsgruppe nicht überschritten werden:
                </p>
                <p>
                  Gruppe I (Unterkunft): 62,5%
                  <br />
                  Gruppe II (Verpflegung): 37,5%
                  <br />
                  Gruppe III (Sonstiges): 30%
                  <br />
                  Gruppe IV (Transport): 20%
                </p>
              </li>
              <li>
                <p>
                  Ist Gegenstand des Vertrages die Leistung von{" "}
                  <b>Unterkunft mit Frühstück</b>, so erhöhen sich die
                  Tabellensätze der Gruppe I (mit Ausnahme der Position I/1) um
                  2/3 = 66,6% und vermindern sich die Tabellensätze der Gruppe
                  II um 2/3 = 66,6%. Dabei dürfen folgende Gesamtprozentsätze
                  innerhalb einer Leistungsgruppe nicht überschritten werden:
                </p>
                <p>
                  Gruppe I (Unterkunft): 83,3%
                  <br />
                  Gruppe II (Verpflegung): 16,7%
                  <br />
                  Gruppe III (Sonstiges): 30%
                  <br />
                  Gruppe IV (Transport): 20%
                </p>
              </li>
              <li>
                <p>
                  Ist Gegenstand des Vertrages nur die Leistung von{" "}
                  <b>Unterkunft (ohne Verpflegung)</b> so erhöhen sich die
                  Tabellensätze der Gruppe I (mit Ausnahme der Position I/1) um
                  100%; im Einzelfall kann der Gesamtprozentsatz der Gruppe I
                  bis 100% gehen. für die Gruppe III verbleibt es beim
                  Gesamtprozentsatz von 30%, für die Gruppe IV beim
                  Gesamtprozentsatz von 20%.
                </p>
                <p>
                  Gruppe I (Unterkunft): 100%
                  <br />
                  Gruppe II (Verpflegung): 0%
                  <br />
                  Gruppe III (Sonstiges): 30%
                  <br />
                  Gruppe IV (Transport): 20%
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Ist die Reise in ihrer Gesamtheit durch Mängel einzelner
              Reiseleistungen oder durch Pflichtverletzungen des
              Reiseveranstalters schuldhaft erheblich beeinträchtigt worden, so
              kann dem Reisenden über die Minderungssätze der Tabelle nach Nr.2
              und über die in Nr.3a vorgesehene Begrenzung auf den betroffenen
              Zeitraum hinaus der Reisepreis ganz oder teilweise als nutzlose
              Aufwendung nach §651f I BGB erstattet werden.
            </p>
          </li>
          <li>
            <p>
              <ol type="a">
                <li>
                  <p>
                    Eine Kündigung nach §651e I BGB kommt in der Regel nur in
                    Betracht, wenn Mängel mit einem Gesamtgewicht von mindestens
                    20% vorliegen. Hierbei ist bei einer Kündigung nach
                    Fristsetzung (§651e II S.1 BGB) auf die nicht fristgerecht
                    behobenen Mängel, bei einer sofortigen Kündigung (§651e II
                    S.2 BGB) auf die bei Abgabe der Kündigungserklärung
                    vorliegenden Mängel abzustellen.
                  </p>
                </li>
                <li>
                  <p>
                    Ein Schadensersatzanspruch nach §651f I BGB in Form der
                    Kosten für einen Ersatzurlaub kommt in der Regel nur in
                    Betracht, wenn — nicht fristgerecht behobene — erhebliche
                    Mängel die Reise derart beeinträchtigt haben, dass eine
                    Ersatzreise für den Reisenden notwendig ist.
                  </p>
                </li>
                <li>
                  <p>
                    Eine Reiseleistung ist ohne Interesse für den Reisenden i.S.
                    des §651e III S.3 BGB, wenn — nicht fristgerecht behobene —
                    Mängel im Gesamtgewicht von mindestens 50% vorgelegen haben.
                  </p>
                </li>
                <li>
                  <p>
                    Im Rahmen dieser Ziff. 6a-c bleiben die in Ziff.4b-d
                    vorgesehene Erhöhung und Verminderung der Prozentsätze außer
                    Betracht.
                  </p>
                </li>
              </ol>
            </p>
          </li>
        </ol>
      </div>
    </Container>
  );
};

export default FrankfurterTabelle;
