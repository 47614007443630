import StepWizard from "react-step-wizard";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import moment from "moment";

import "bootstrap-icons/font/bootstrap-icons.css";

import {
  FormControl,
  InputGroup,
  Card,
  Form,
  Row,
  Col,
  Button,
  Alert,
  Table,
  OverlayTrigger,
  Popover,
  Container,
  Modal,
} from "react-bootstrap";
import Nav from "../Components/nav";

//display settings
const DISPLAY_SETTINGS_MAIN = {
  md: "12",
};

const DISPLAY_SETTINGS_TWO_COL = {
  md: "6",
};

const DISPLAY_SETTINGS_THREE_COL = {
  md: "4",
};

const DISPLAY_SETTINGS_FOUR_COL = {
  md: "3",
};

//deficiency data with kemptner table
const DEFICIENCY_DATA_ADVANCED = [
  {
    id: "1",
    cluster: "vor Reisebeginn",
    "Unterkunft und Vollpension": -1,
    "Unterkunft und Halbpension": -1,
    "Unterkunft mit Frühstück": -1,
    "Unterkunft (ohne Verpflegung)": -1,
    deficiencies: [
      {
        deficiency: "Unterlassene Unterrichtung über Visumspflicht",
        comment: "",
        reduction_min: 100,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency:
          "Nichterreichen des Hinflugs wg. Falscher Info über Check-In-Zeit",
        comment: "",
        reduction_min: 100,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency:
          'Unterlassene Aufklärung über Nichteinhaltung eines "unverbindlichen Kundenwunsches"',
        comment: "z.B. bei fehlender Poolrutsche",
        reduction_min: 12.5,
        reduction_max: 12.5,
        calculation: "total",
      },
      {
        deficiency: "Unterlassene Aufklärung über Überbuchung des Hotels",
        comment: "",
        reduction_min: 15,
        reduction_max: 15,
        calculation: "total",
      },
    ],
  },
  {
    id: "2",
    cluster: "Transport",
    "Unterkunft und Vollpension": -1,
    "Unterkunft und Halbpension": -1,
    "Unterkunft mit Frühstück": -1,
    "Unterkunft (ohne Verpflegung)": -1,
    deficiencies: [
      {
        deficiency: "Abflug mit großer zeitlicher Verschiebung",
        comment: "",
        reduction_min: 5,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency: "Schlechtgere Fluggesellschaft als vereinbart",
        comment: "",
        reduction_min: 5,
        reduction_max: 25,
        calculation: "total",
      },
      {
        deficiency: "Beförderung in schlechterer Klasse als zugesagt",
        comment: "",
        reduction_min: 10,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency: "Mängel bei der Ausstattung - Abweichung vom Standard",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "total",
      },
      {
        deficiency: "kein Transfer vom Flugplatz oder Bahnhof",
        comment: "Erstattung der Kosten des Ersatztransportmittels",
        reduction_min: 0,
        reduction_max: 0,
        calculation: "total",
      },
      {
        deficiency:
          "Transportverzögerung durch Auswechseln des Transportmittels",
        comment: "Anteiliger auf die Verzögerung fallender Reisepreis",
        reduction_min: 0,
        reduction_max: 0,
        calculation: "total",
      },
      {
        deficiency: "Änderung des Zielflughafens & des Transfers",
        comment: "",
        reduction_min: 25,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency: "Hin- oder Rückflug unzumutbar früher oder später",
        comment:
          "(z.B. Rückflug 22:30 Uhr statt 9:15, oder 5:00 statt 15:00 oder Hinflug erst am Folgetag) ",
        reduction_min: 25,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency:
          "Nichterreichen des Reiseziels, da Anschlussflug wegen Verspätung verpasst wurde",
        comment: "",
        reduction_min: 100,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency: "Unplanmäßige Zwischenlandung(en)",
        comment: "",
        reduction_min: 50,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency: "Verpasster Rückflug durch vergessene Abholung am Hotel",
        comment: "",
        reduction_min: 100,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency:
          "Fehlen der in der Flugklasse üblichen Unterhaltung (Radio, Film, etc.)",
        comment: "",
        reduction_min: 5,
        reduction_max: 5,
        calculation: "total",
      },
    ],
  },
  {
    id: "3",
    cluster: "Unterkunft",
    "Unterkunft und Vollpension": -1,
    "Unterkunft und Halbpension": -1,
    "Unterkunft mit Frühstück": -1,
    "Unterkunft (ohne Verpflegung)": -1,
    deficiencies: [
      {
        deficiency: "Ersatz - Nötiger Umzug innerhalb desselben Hotels",
        comment: "",
        reduction_min: 0,
        reduction_max: 50,
        calculation: "total",
      },
      {
        deficiency: "Ersatz - Nötiger Umzug in ein anderes Hotel",
        comment: "",
        reduction_min: 50,
        reduction_max: 100,
        calculation: "total",
      },
      {
        deficiency:
          "Ersatz - nicht die gebuchte Unterkunft, weitere Strandentfernung",
        comment: "",
        reduction_min: 5,
        reduction_max: 30,
        calculation: "perday",
      },
      {
        deficiency:
          "Ersatz - nicht die gebuchte Unterkunft : Hotel statt Bungalow",
        comment: "",
        reduction_min: 5,
        reduction_max: 15,
        calculation: "perday",
      },
      {
        deficiency: "Ersatz - Überbuchung: Ersatzunterkunft nicht zumutbar",
        comment:
          "Kündigung gerechtfertig nach § 651 c BGB, Erstattung der Kosten für selbstorganisiertes Hotel",
        reduction_min: 100,
        reduction_max: 100,
        calculation: "perday",
      },
      {
        deficiency: "Ersatz - Unterbringung auf anderer Insel",
        comment: "",
        reduction_min: 20,
        reduction_max: 50,
        calculation: "perday",
      },
      {
        deficiency: "Ersatz - abweichende Art des Zimmers (z.B. DZ statt EZ)",
        comment: "",
        reduction_min: 20,
        reduction_max: 55.00000000000001,
        calculation: "perday",
      },
      {
        deficiency: "Ausstattung - Zimmerfläche zu klein",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Ausstattung - fehlendes eigenes Bad/WC",
        comment: "bei Buchung/Zusage",
        reduction_min: 15,
        reduction_max: 25,
        calculation: "perday",
      },
      {
        deficiency: "Ausstattung - fehlendes eigenes WC",
        comment: "",
        reduction_min: 15,
        reduction_max: 15,
        calculation: "perday",
      },
      {
        deficiency: "Ausstattung - fehlende eigene Dusche",
        comment: "bei Buchung/Zusage",
        reduction_min: 10,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Ausstattung - fehlender Balkon",
        comment: "bei Buchung/Zusage",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Ausstattung - fehlender Meerblick",
        comment: "bei Buchung/Zusage",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Ausstattung - fehlende Klimaanlage",
        comment: "bei Buchung/Zusage",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Ausstattung - Risse in den Wänden",
        comment: "",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Ausstattung - feuchte oder schimmlige Wände",
        comment: "",
        reduction_min: 10,
        reduction_max: 50,
        calculation: "perday",
      },
      {
        deficiency: "Versorgungsausfall - defekte Toilette, Bad",
        comment: "",
        reduction_min: 15,
        reduction_max: 15,
        calculation: "perday",
      },
      {
        deficiency: "Versorgungsausfall - defekter Warmwasserboiler",
        comment: "",
        reduction_min: 15,
        reduction_max: 15,
        calculation: "perday",
      },
      {
        deficiency: "Versorgungsausfall - defekter Fahrstuhl",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Versorgungsausfall - kein Strom, kein Wasser",
        comment: "",
        reduction_min: 10,
        reduction_max: 40,
        calculation: "perday",
      },
      {
        deficiency: "Versorgungsausfall - Defekte Klimanalage",
        comment: "Je nach Jahreszeit",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Beeinträchtigung - Ungeziefer im Zimmer",
        comment: "",
        reduction_min: 10,
        reduction_max: 50,
        calculation: "perday",
      },
      {
        deficiency: "Beeinträchtigung - Lärmbeeinträchtigung am Tag",
        comment: "",
        reduction_min: 5,
        reduction_max: 60,
        calculation: "perday",
      },
      {
        deficiency: "Beeinträchtigung - Lärmbeeinträchtigung in der Nacht",
        comment: "z.B. durch Großbaustelle oder Open-Air-Disco",
        reduction_min: 10,
        reduction_max: 75,
        calculation: "perday",
      },
      {
        deficiency: "Beeinträchtigung - Geruchsbelästigung",
        comment: "",
        reduction_min: 5,
        reduction_max: 15,
        calculation: "perday",
      },
      {
        deficiency: "Service - Vollkommener Ausfalls",
        comment: "",
        reduction_min: 25,
        reduction_max: 25,
        calculation: "perday",
      },
      {
        deficiency: "Service - Schlechte Reinigung",
        comment: "",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Service - ungenügender Wechsel von Bettwäsche/Handtüchern",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
    ],
  },
  {
    id: "4",
    cluster: "Verpflegung",
    "Unterkunft und Vollpension": -1,
    "Unterkunft und Halbpension": -1,
    "Unterkunft mit Frühstück": -1,
    "Unterkunft (ohne Verpflegung)": -1,
    deficiencies: [
      {
        deficiency: "Qualität - Eintönige Speiseauswahl",
        comment: "",
        reduction_min: 5,
        reduction_max: 5,
        calculation: "perday",
      },
      {
        deficiency: "Qualität - Nicht genügend warme Speisen",
        comment: "",
        reduction_min: 10,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Qualität - Speisen verdorben oder ungenießbar",
        comment: "",
        reduction_min: 20,
        reduction_max: 30,
        calculation: "perday",
      },
      {
        deficiency:
          "Qualität - erhebliche Gesundheitsbeeinträchtigung durch Hotelessen",
        comment: "z.B. Salmonellenerkrankung, Ciquatera-Fischvergiftung",
        reduction_min: 20,
        reduction_max: 100,
        calculation: "perday",
      },
      {
        deficiency: "Service  - Selbstbedienung statt Kellner",
        comment: "",
        reduction_min: 10,
        reduction_max: 15,
        calculation: "perday",
      },
      {
        deficiency: "Service  - lange Wartezeiten",
        comment: "",
        reduction_min: 5,
        reduction_max: 15,
        calculation: "perday",
      },
      {
        deficiency: "Service  - Essen in Schichten",
        comment: "",
        reduction_min: 10,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Service  - Verschmutze Tische",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Service  - Verschmutztes Geschirr, Besteck",
        comment: "",
        reduction_min: 10,
        reduction_max: 15,
        calculation: "perday",
      },
    ],
  },
  {
    id: "5",
    cluster: "Spezialreisen",
    "Unterkunft und Vollpension": -1,
    "Unterkunft und Halbpension": -1,
    "Unterkunft mit Frühstück": -1,
    "Unterkunft (ohne Verpflegung)": -1,
    deficiencies: [
      {
        deficiency: "Ausfall von Landausflügen bei Kreuzfahrten",
        comment: "",
        reduction_min: 20,
        reduction_max: 30,
        calculation: "perday",
      },
      {
        deficiency: "Wohnmobilreise: Gravierende technische Mängel am Fahrzeug",
        comment: "",
        reduction_min: 40,
        reduction_max: 40,
        calculation: "perday",
      },
    ],
  },
  {
    id: "6",
    cluster: "Sonstige Mängel",
    "Unterkunft und Vollpension": -1,
    "Unterkunft und Halbpension": -1,
    "Unterkunft mit Frühstück": -1,
    "Unterkunft (ohne Verpflegung)": -1,
    deficiencies: [
      {
        deficiency: "Fehlender oder stark verschmutzter Swimmingpool",
        comment: "bei Zusage",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Fehlendes Hallenbad",
        comment: "bei Zusage; auch abhängig von Vorhandensein eines Pools",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Fehlende Sauna",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 5,
        calculation: "perday",
      },
      {
        deficiency: "Kein Tennisplatz",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Fehlende Mini-Golf-Anlage",
        comment: "bei Zusage",
        reduction_min: 3,
        reduction_max: 5,
        calculation: "perday",
      },
      {
        deficiency: "Keine Tauch-, Segel- oder Surfschule",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Keine Reitmöglichkeit",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Keine Kinderbetreuung",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Keine Disco, Nachtclub, Kino, Animateure",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 15,
        calculation: "perday",
      },
      {
        deficiency: "Keine Boutique oder Ladenstraße",
        comment: "bei Zusage, je nach Ausweichmöglichkeit",
        reduction_min: 0,
        reduction_max: 5,
        calculation: "perday",
      },
      {
        deficiency: "Keine Bademöglichkeit am Meer",
        comment: "bei Zusage",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Keine Strandliegen oder Sonnenschirme",
        comment:
          "bei Zusage, Prospektbeschreibung und je nach zumutbarer Ausweichmöglichkeit",
        reduction_min: 5,
        reduction_max: 10,
        calculation: "perday",
      },
      {
        deficiency: "Strand verschmutzt",
        comment: "",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "kein FKK-Strand",
        comment: "bei Zusage",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency:
          "Kein Restaurant, Snack- oder Strandbar oder kein  Supermarkt",
        comment: "bei Zusage; je nach Verpflegungsart und Ausweichmöglchkeit",
        reduction_min: 0,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Fehlende Reiseleitung bzgl. Organisation",
        comment: "",
        reduction_min: 0,
        reduction_max: 5,
        calculation: "perday",
      },
      {
        deficiency: "Fehlende Reiseleitung bei Besichtigungstouren",
        comment: "",
        reduction_min: 10,
        reduction_max: 20,
        calculation: "perday",
      },
      {
        deficiency: "Fehlende Reiseleitung bei Studienreisen",
        comment: "",
        reduction_min: 20,
        reduction_max: 30,
        calculation: "perday",
      },
    ],
  },
];

//deficiency data with kemptner table
const DEFICIENCY_DATA_FRANKFURT = [
  {
    id: "1",
    cluster: "Unterkunft",
    "Unterkunft und Vollpension": 50,
    "Unterkunft und Halbpension": 62.5,
    "Unterkunft mit Frühstück": 83.3,
    "Unterkunft (ohne Verpflegung)": 100,
    deficiencies: [
      {
        deficiency: "Ersatz - nicht das gebuchte Objekt",
        comment: "",
        reduction_min: 10,
        reduction_max: 25,
      },
      {
        deficiency:
          "Ersatz - nicht die gebuchte Unterkunft, weitere Strandentfernung",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency:
          "Ersatz - nicht die gebuchte Unterkunft : Hotel statt Bungalow",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Art d. Zimmer - DZ statt EZ",
        comment: "",
        reduction_min: 20,
        reduction_max: 20,
      },
      {
        deficiency: "Art d. Zimmer - Dreibettz. statt EZ",
        comment: "",
        reduction_min: 25,
        reduction_max: 25,
      },
      {
        deficiency: "Art d. Zimmer - Dreibettz. statt DZ",
        comment: "entscheidend, ob mit unbekannten Reisenden zusammengelegt",
        reduction_min: 20,
        reduction_max: 25,
      },
      {
        deficiency: "Art d. Zimmer - Viebttz. statt DZ",
        comment: "entscheidend, ob mit unbekannten Reisenden zusammengelegt",
        reduction_min: 20,
        reduction_max: 30,
      },
      {
        deficiency: "Ausstattung - Zimmerfläche zu klein",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Ausstattung - fehlendes eigenes Bad/WC",
        comment: "bei Buchung/Zusage",
        reduction_min: 15,
        reduction_max: 25,
      },
      {
        deficiency: "Ausstattung - fehlendes eigenes WC",
        comment: "",
        reduction_min: 15,
        reduction_max: 15,
      },
      {
        deficiency: "Ausstattung - fehlende eigene Dusche",
        comment: "bei Buchung/Zusage",
        reduction_min: 10,
        reduction_max: 10,
      },
      {
        deficiency: "Ausstattung - fehlender Balkon",
        comment: "bei Buchung/Zusage, je nach Jahreszeit",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Ausstattung - fehlender Meerblick",
        comment: "bei Buchung/Zusage",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Ausstattung - fehlende Klimaanlage",
        comment: "bei Buchung/Zusage",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Ausstattung - fehlendes TV/Radio",
        comment: "bei Buchung/Zusage",
        reduction_min: 5,
        reduction_max: 5,
      },
      {
        deficiency: "Ausstattung - Zu geringes Mobiliar",
        comment: "",
        reduction_min: 5,
        reduction_max: 15,
      },
      {
        deficiency: "Ausstattung - Schäden: Risse in den Wänden",
        comment: "",
        reduction_min: 10,
        reduction_max: 50,
      },
      {
        deficiency: "Ausstattung - feuchte oder schimmlige Wände",
        comment: "",
        reduction_min: 10,
        reduction_max: 50,
      },
      {
        deficiency: "Ausstattung - Ungeziefer im Zimmer",
        comment: "",
        reduction_min: 10,
        reduction_max: 50,
      },
      {
        deficiency: "Versorgungsausfall - defekte Toilette",
        comment: "",
        reduction_min: 15,
        reduction_max: 15,
      },
      {
        deficiency:
          "Versorgungsausfall - defektes Bad, defekter Warmwasserboiler",
        comment: "",
        reduction_min: 15,
        reduction_max: 15,
      },
      {
        deficiency: "Versorgungsausfall - defekter Fahrstuhl",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Versorgungsausfall - kein Strom, kein Gas",
        comment: "",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Versorgungsausfall - kein Wasser",
        comment: "",
        reduction_min: 10,
        reduction_max: 10,
      },
      {
        deficiency: "Versorgungsausfall - Defekte Klimanalage",
        comment: "Je nach Jahreszeit",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Service - Vollkommener Ausfall",
        comment: "",
        reduction_min: 25,
        reduction_max: 25,
      },
      {
        deficiency: "Service - Schlechte Reinigung",
        comment: "",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Service - ungenügender Wechsel von Bettwäsche/Handtüchern",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Beeinträchtigung - Lärmbeeinträchtigung am Tag",
        comment: "",
        reduction_min: 5,
        reduction_max: 25,
      },
      {
        deficiency: "Beeinträchtigung - Lärmbeeinträchtigung in der Nacht",
        comment: "z.B. durch Großbaustelle oder Open-Air-Disco",
        reduction_min: 10,
        reduction_max: 40,
      },
      {
        deficiency: "Beeinträchtigung - Geruchsbelästigung",
        comment: "",
        reduction_min: 5,
        reduction_max: 15,
      },
      {
        deficiency:
          "Kureinrichtungen - Fehlen von Kureinrichtungen (Thermalbad, Massagen)",
        comment: 'bei Zusage, je nach Art der Projektzusage (z.B. "Kururlaub")',
        reduction_min: 20,
        reduction_max: 40,
      },
    ],
  },
  {
    id: "2",
    cluster: "Verpflegung",
    "Unterkunft und Vollpension": 50,
    "Unterkunft und Halbpension": 37.5,
    "Unterkunft mit Frühstück": 16.7,
    "Unterkunft (ohne Verpflegung)": 0,
    deficiencies: [
      {
        deficiency: "Qualität - Eintönige Speiseauswahl",
        comment: "",
        reduction_min: 5,
        reduction_max: 5,
      },
      {
        deficiency: "Qualität - Nicht genügend warme Speisen",
        comment: "",
        reduction_min: 10,
        reduction_max: 10,
      },
      {
        deficiency: "Qualität - Speisen verdorben oder ungenießbar",
        comment: "",
        reduction_min: 20,
        reduction_max: 30,
      },
      {
        deficiency: "Service  - Selbstbedienung statt Kellner",
        comment: "",
        reduction_min: 10,
        reduction_max: 15,
      },
      {
        deficiency: "Service  - lange Wartezeiten",
        comment: "",
        reduction_min: 5,
        reduction_max: 15,
      },
      {
        deficiency: "Service  - Essen in Schichten",
        comment: "",
        reduction_min: 10,
        reduction_max: 10,
      },
      {
        deficiency: "Service  - Verschmutze Tische",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Service  - Verschmutztes Geschirr, Besteck",
        comment: "",
        reduction_min: 10,
        reduction_max: 15,
      },
    ],
  },
  {
    id: "3",
    cluster: "Sonstige Mängel",
    "Unterkunft und Vollpension": 30,
    "Unterkunft und Halbpension": 30,
    "Unterkunft mit Frühstück": 30,
    "Unterkunft (ohne Verpflegung)": 30,
    deficiencies: [
      {
        deficiency: "Fehlender oder stark verschmutzter Swimmingpool",
        comment: "bei Zusage",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Fehlendes Hallenbad",
        comment: "bei Zusage; auch abhängig von Vorhandensein eines Pools",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Fehlende Sauna",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 5,
      },
      {
        deficiency: "Kein Tennisplatz",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Fehlende Mini-Golf-Anlage",
        comment: "bei Zusage",
        reduction_min: 3,
        reduction_max: 5,
      },
      {
        deficiency: "Keine Tauch-, Segel- oder Surfschule",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Keine Reitmöglichkeit",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Keine Kinderbetreuung",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 20,
      },
      {
        deficiency: "Keine Bademöglichkeit am Meer",
        comment: "bei Zusage",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Strand verschmutzt",
        comment: "",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Keine Strandliegen oder Sonnenschirme",
        comment:
          "bei Zusage, Prospektbeschreibung und je nach zumutbarer Ausweichmöglichkeit",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Keine Snack- oder Strandbar",
        comment: "bei Zusage; je nach Ersatzmöglichkeit",
        reduction_min: 0,
        reduction_max: 5,
      },
      {
        deficiency: "kein FKK-Strand",
        comment: "bei Zusage",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Kein Restaurant oder kein  Supermarkt",
        comment: "bei Zusage; je nach Verpflegungsart und Ausweichmöglchkeit",
        reduction_min: 0,
        reduction_max: 20,
      },
      {
        deficiency: "Keine Disco, Nachtclub, Kino, Animateure",
        comment: "bei Zusage",
        reduction_min: 5,
        reduction_max: 15,
      },
      {
        deficiency: "Keine Boutique oder Ladenstraße",
        comment: "bei Zusage, je nach Ausweichmöglichkeit",
        reduction_min: 0,
        reduction_max: 5,
      },
      {
        deficiency: "Ausfall von Landausflügen bei Kreuzfahrten",
        comment: "",
        reduction_min: 20,
        reduction_max: 30,
      },
      {
        deficiency: "Fehlende Reiseleitung bzgl. Organisation",
        comment: "",
        reduction_min: 0,
        reduction_max: 5,
      },
      {
        deficiency: "Fehlende Reiseleitung bei Besichtigungstouren",
        comment: "",
        reduction_min: 10,
        reduction_max: 20,
      },
      {
        deficiency: "Fehlende Reiseleitung bei Studienreisen",
        comment: "",
        reduction_min: 20,
        reduction_max: 30,
      },
      {
        deficiency: "Nötiger Umzug innerhalb desselben Hotels",
        comment: "",
        reduction_min: 0,
        reduction_max: 50,
      },
      {
        deficiency: "Nötiger Umzug in ein anderes Hotel",
        comment: "",
        reduction_min: 50,
        reduction_max: 100,
      },
    ],
  },
  {
    id: "4",
    cluster: "Transport",
    "Unterkunft und Vollpension": 20,
    "Unterkunft und Halbpension": 20,
    "Unterkunft mit Frühstück": 20,
    "Unterkunft (ohne Verpflegung)": 20,
    deficiencies: [
      {
        deficiency:
          "Abflug mit großer zeitlicher Verschiebung über 4 Stunden hinaus",
        comment: "5% pro weitere Stunde",
        reduction_min: 5,
        reduction_max: 100,
      },
      {
        deficiency: "Beförderung in schlechterer Klasse als zugesagt",
        comment: "",
        reduction_min: 10,
        reduction_max: 15,
      },
      {
        deficiency:
          "Mängel bei der Ausstattung - Erhebliche Abweichung vom Standard",
        comment: "",
        reduction_min: 5,
        reduction_max: 10,
      },
      {
        deficiency: "Mängel bei der Verpflegung",
        comment: "",
        reduction_min: 5,
        reduction_max: 5,
      },
      {
        deficiency:
          "Fehlen der in der Flugklasse üblichen Unterhaltung (Radio, Film, etc.)",
        comment: "",
        reduction_min: 5,
        reduction_max: 5,
      },
      {
        deficiency:
          "Transportverzögerung durch Auswechseln des Transportmittels",
        comment: "Anteiliger auf die Verzögerung fallender Reisepreis",
        reduction_min: 0,
        reduction_max: 0,
      },
      {
        deficiency: "kein Transfer vom Flugplatz oder Bahnhof",
        comment: "Erstattung der Kosten des Ersatztransportmittels",
        reduction_min: 0,
        reduction_max: 0,
      },
    ],
  },
];

//limits for different categories (advanced mode)
const VACATION_CATEGORIES = [
  "Unterkunft und Vollpension",
  "Unterkunft und Halbpension",
  "Unterkunft mit Frühstück",
  "Unterkunft (ohne Verpflegung)",
];

/*
//limits for different categories (advanced mode)
const LIMITS_ADVANCED = [
  { category: "Unterkunft und Vollpension", limits: [-1, -1, -1, -1, -1, -1] },
  { category: "Unterkunft und Halbpension", limits: [-1, -1, -1, -1, -1, -1] },
  { category: "Unterkunft mit Frühstück", limits: [-1, -1, -1, -1, -1, -1] },
  {
    category: "Unterkunft (ohne Verpflegung)",
    limits: [-1, -1, -1, -1, -1, -1],
  },
];

//limits for different categories (frankfurter mode)
const LIMITS_FRANKFURT = [
  { category: "Unterkunft und Vollpension", limits: [50, 50, 30, 20] },
  { category: "Unterkunft und Halbpension", limits: [62.5, 37.5, 30, 20] },
  { category: "Unterkunft mit Frühstück", limits: [83.3, 16.7, 30, 20] },
  { category: "Unterkunft (ohne Verpflegung)", limits: [100, 0, 30, 20] },
];
*/

//Component of first step of step wizard
const BasicData = (props) => {
  //defining hooks to save data and update UI
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).subtract(1, "d").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [show, setShow] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [validated, setValidated] = React.useState(false);
  const [legalChecked, setLegalChecked] = React.useState(false);

  //Used to validate and save start date of vacation
  const startDateHandler = (event) => {
    //variable has changed to set this step to unvalidated
    props.setValidatedStep1(false);

    //if data is not empty
    if (event.target.value !== "") {
      //check if end date is earlier than start date
      if (new Date(endDate) - new Date(event.target.value) < 1) {
        //add alert message and make visible
        setAlertMessage(
          "Startdatum muss mindestens einen Tag früher als Enddatum sein."
        );
        setShow(true);

        //set start date to default as the entered value was wrong
        setStartDate(new Date());

        //if date is correct
      } else {
        //save date
        setStartDate(event.target.value);
        //hide alert box
        setShow(false);
        //update vacation duration variable
        props.setVacationDurationHandler(
          new Date(event.target.value),
          new Date(endDate)
        );
      }
    }
  };

  //Used to validate and end start date of vacation
  const endDateHandler = (event) => {
    //variable has changed to set this step to unvalidated
    props.setValidatedStep1(false);

    //check if empty
    if (event.target.value !== "") {
      //check if end date is earlier than start date
      if (new Date(event.target.value) - new Date(startDate) < 1) {
        //set alert message and show it
        setAlertMessage(
          "Startdatum muss mindestens einen Tag früher als Enddatum sein."
        );
        setShow(true);

        //clear end date as it is wrong
        setEndDate(new Date());

        //start date is earlier than end date, which is correct
      } else {
        //save data and hide alert
        setEndDate(event.target.value);
        setShow(false);

        //update vacation duration
        props.setVacationDurationHandler(
          new Date(startDate),
          new Date(event.target.value)
        );
      }
    }
  };

  //is called when clicked on "weiter"
  const validateInputAndNext = (event) => {
    //set the validator to false as we are validating now
    props.setValidatedStep1(false);
    setValidated(false);

    //get form and prevent that form is submitted and html reloads page
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    //if form is correct go to next step
    if (form.checkValidity() === true) {
      props.nextStep();
      props.setValidatedStep1(true);
    }

    setValidated(true);

    props.trackEvent({
      category: "calculator",
      action: "click-step1-weiter-btn",
    });
  };

  //alert box which is used for different alerts in this component
  function AlertDismissible() {
    return (
      <>
        <Alert show={show} variant="warning">
          <Row>
            <Col lg="10" className="d-flex justify-content-start">
              {alertMessage}
            </Col>
            <Col lg="2" className="d-flex justify-content-end">
              <i
                className="bi-x-lg d-flex justify-content-end"
                onClick={() => setShow(false)}
              ></i>
            </Col>
          </Row>
        </Alert>
      </>
    );
  }

  //return html which is show in this step
  return (
    <Card className="wizard_background">
      <Card.Body>
        <Card.Title className="mb-4">Wann war deine Reise:</Card.Title>
        <Card.Text>
          <AlertDismissible />
          <Form
            noValidate
            validated={validated}
            onSubmit={validateInputAndNext}
          >
            <Row className="text-start">
              <Col {...DISPLAY_SETTINGS_THREE_COL}>
                <Form.Group className="mb-3">
                  <Form.Label>Reiseveranstalter:</Form.Label>
                  <Form.Select
                    aria-label="Reiseveranstalter"
                    onChange={(event) =>
                      props.setTravelAgency(event.target.value)
                    }
                  >
                    <option key="travelagency-1" value="1">
                      Schauinsland Reisen
                    </option>
                    <option key="travelagency-2" value="2">
                      Alltours
                    </option>
                    <option key="travelagency-3" value="3">
                      FTI Gruppe
                    </option>
                    <option key="travelagency-4" value="4">
                      TUI Deutschland
                    </option>
                    <option key="travelagency-5" value="5">
                      ITS
                    </option>
                    <option key="travelagency-6" value="6">
                      Öger Tours
                    </option>
                    <option key="travelagency-7" value="7">
                      Bucher Reisen
                    </option>
                    <option key="travelagency-8" value="8">
                      ETI
                    </option>
                    <option key="travelagency-9" value="9">
                      JT Touristik
                    </option>
                    <option key="travelagency-10" value="10">
                      Ameropa Reisen
                    </option>
                    <option key="travelagency-11" value="11">
                      Jahn Reisen
                    </option>
                    <option key="travelagency-12" value="12">
                      5vorFlug
                    </option>
                    <option key="travelagency-13" value="13">
                      ADAC Reisen
                    </option>
                    <option key="travelagency-14" value="14">
                      1-2-FLY
                    </option>
                    <option key="travelagency-15" value="15">
                      LMX Touristik GmbH
                    </option>
                    <option key="travelagency-16" value="16">
                      Byebye Reisen
                    </option>
                    <option key="travelagency-17" value="17">
                      Meiers Weltreisen
                    </option>
                    <option key="travelagency-18" value="18">
                      Tropo GmbH
                    </option>
                    <option key="travelagency-19" value="19">
                      DER Touristik Deutschland
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col {...DISPLAY_SETTINGS_THREE_COL}>
                <Form.Group className="mb-3">
                  <Form.Label>Anreise:</Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={startDateHandler}
                    required
                  />
                </Form.Group>
              </Col>
              <Col {...DISPLAY_SETTINGS_THREE_COL}>
                <Form.Group className="mb-3">
                  <Form.Label>Abreise:</Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={endDateHandler}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="text-start">
              <Col {...DISPLAY_SETTINGS_THREE_COL}>
                <Form.Group className="mb-3">
                  <Form.Label>Erwachsene:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="0"
                    required
                    min="1"
                    value={props.adultsAmount}
                    onChange={(event) => {
                      props.setValidatedStep1(false);
                      props.setAdultsAmount(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col {...DISPLAY_SETTINGS_THREE_COL}>
                <Form.Group className="mb-3">
                  <Form.Label>Kategorie:</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      props.setVacationCategory(e.target.selectedIndex)
                    }
                  >
                    {VACATION_CATEGORIES.map((item, index) => {
                      return (
                        <option
                          key={`vacation-category-${index}`}
                          value={props.vacationCategory === index}
                        >
                          {VACATION_CATEGORIES[index]}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col {...DISPLAY_SETTINGS_THREE_COL}>
                <Form.Group className="mb-3">
                  <Form.Label>Kosten:</Form.Label>
                  <InputGroup>
                    <FormControl
                      id="inlineFormInputGroupUsername"
                      placeholder=""
                      type="number"
                      value={props.costOfVacation}
                      key="input-cost"
                      min="1"
                      onChange={props.changeCostOfVacationHandler}
                      required
                    />
                    <InputGroup.Text>€</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-3 text-start">
              <Col className="">
                <Form.Check
                  className="m-0"
                  type="checkbox"
                  id="disclaimer-checkbox"
                  checked={legalChecked}
                  required
                  onChange={(event) => setLegalChecked(event.target.checked)}
                  label={
                    <div>
                      <span>Ich habe den </span>
                      <Link to={"/haftungsausschluss"} target="_blank">
                        Disclaimer
                      </Link>
                      <span>
                        {" "}
                        gelesen und bin mir im klaren darüber, dass es sich bei
                        diesem Tool um keine verbindliche rechtliche Beratung
                        handelt.{" "}
                      </span>
                    </div>
                  }
                />
              </Col>
            </Row>
            <Row className="text-end">
              <Col {...DISPLAY_SETTINGS_MAIN}>
                <Button type="submit">Weiter</Button>
              </Col>
            </Row>
          </Form>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

//Component of second step of step wizard
const Reisedaten = (props) => {
  //hooks to handle data

  const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [selectedDeficiency, setSelectedDeficiency] = React.useState(0);

  const [frankfurterTabelle, setFrankfurterTabelle] = React.useState(false);

  //set deficiency array based on mode
  const [deficiencyData, setDeficiencyData] = React.useState(
    frankfurterTabelle ? DEFICIENCY_DATA_FRANKFURT : DEFICIENCY_DATA_ADVANCED
  );

  //used for selection of defieciencies
  const [selectArray, setSelectArray] = React.useState(
    frankfurterTabelle
      ? DEFICIENCY_DATA_FRANKFURT[0].deficiencies
      : DEFICIENCY_DATA_ADVANCED[0].deficiencies
  );

  //saves deficiences set by user
  const [deficienciesArray, setDeficienciesArray] = React.useState([]);

  //used to show alert box
  const [show, setShow] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  //disables deficiency duration field
  const [deficiencyDurationFieldDisabled, setDeficiencyDurationFieldDisabled] =
    React.useState(
      frankfurterTabelle
        ? DEFICIENCY_DATA_FRANKFURT[0].deficiencies[0].calculation === "total"
          ? true
          : false
        : DEFICIENCY_DATA_ADVANCED[0].deficiencies[0].calculation === "total"
        ? true
        : false
    );

  //state to show and hide modal after switching calculator
  const [showModal, setShowModal] = React.useState(false);

  //needed to avoid infinite loop. Calculate the results of all deficiencies. TODO: There is a callback missing
  React.useEffect(() => {
    calculateResults();
  }, [deficienciesArray]);

  //saves data and handles UI if select form is used
  const categoryChangeHandler = (event) => {
    resetDeficiencySelect(event.target.selectedIndex, deficiencyData);
  };

  //function to reset deficiencies select field after new category has been chosen
  const resetDeficiencySelect = (selectedIndex, newDataArray) => {
    setSelectedDeficiency(0);

    if (newDataArray[selectedIndex].deficiencies[0].calculation === "total") {
      props.setDeficiencyDuration(1);
      setDeficiencyDurationFieldDisabled(true);
    } else {
      props.setDeficiencyDuration(props.vacationDuration);
      setDeficiencyDurationFieldDisabled(false);
    }

    setSelectArray(() => newDataArray[selectedIndex].deficiencies);
    setSelectedCategory(() => selectedIndex);
  };

  //saves selected deficiency in drop down
  const deficiencyChangeHandler = (event) => {
    setShow(false);
    setSelectedDeficiency(event.target.selectedIndex);
    if (selectArray[event.target.selectedIndex].calculation === "total") {
      props.setDeficiencyDuration(1);
      setDeficiencyDurationFieldDisabled(true);
    } else {
      props.setDeficiencyDuration(props.vacationDuration);
      setDeficiencyDurationFieldDisabled(false);
    }
  };

  //Called when deficiency duration is changed. Makes sure that deficiency duration is not longer than vacation
  const deficiencyDurationHandler = (event) => {
    setShow(false);

    if (props.vacationDuration < event.target.value) {
      setAlertMessage(
        "Mangeldauer kann nicht länger als Reisedauer sein. Dauer wird auf Maximum gesetzt."
      );
      setShow(true);
      props.setDeficiencyDuration(props.vacationDuration);
    } else {
      props.setDeficiencyDuration(event.target.value);
    }
  };

  //delete a deficiency from the table
  const deleteDeficiencyHandler = (index) => (event) => {
    props.setValidatedStep2(false);

    setShow(false);
    setDeficienciesArray((deficienciesArray) => {
      return [
        ...deficienciesArray.slice(0, index),
        ...deficienciesArray.slice(index + 1),
      ];
    });
  };

  //add a deficiency to the table if data is correct. If not shows alert.
  const addDeficiency = () => {
    props.setValidatedStep2(false);

    let foundItem = false;
    deficienciesArray.forEach((item) => {
      if (
        selectArray[selectedDeficiency] === item.deficiency &&
        props.deficiencyDuration === item.days
      )
        foundItem = true;
    });

    if (foundItem) {
      setAlertMessage(
        "Mangel existiert bereits und kann nicht erneut hinzugefügt werden."
      );
      setShow(true);
    } else if (props.deficiencyDuration < 1) {
      setAlertMessage("Die Dauer des Mangels muss mindestens 1 Tag sein.");
      setShow(true);
    } else {
      setShow(false);

      setDeficienciesArray((oldArray) => [
        ...oldArray,
        {
          deficiency: selectArray[selectedDeficiency],
          days: props.deficiencyDuration,
          category: selectedCategory,
        },
      ]);
    }
  };

  //calculates the results for the user
  const calculateResults = () => {
    let returnArray = [];
    let returnTotals = { min: 0, max: 0 };

    deficiencyData.forEach((item, index) => {
      if (
        deficiencyData[index][VACATION_CATEGORIES[props.vacationCategory]] ===
        -1
      )
        returnArray[index] = { category: index, min: 0, max: 0, catMax: -1 };
      else {
        console.log(
          "bla" +
            props.costOfVacation +
            ":" +
            deficiencyData[index][VACATION_CATEGORIES[props.vacationCategory]]
        );
        returnArray[index] = {
          category: index,
          min: 0,
          max: 0,
          catMax:
            (props.costOfVacation *
              deficiencyData[index][
                VACATION_CATEGORIES[props.vacationCategory]
              ]) /
            100,
        };
      }
    });

    console.log("länge deficiency array: " + deficienciesArray.length);
    deficienciesArray.forEach((item) => {
      let min = 0;
      let max = 0;

      console.log(item.category + ":" + returnArray);

      if (returnArray[item.category].min !== 0)
        min = returnArray[item.category].min;

      if (returnArray[item.category].max !== 0)
        max = returnArray[item.category].max;

      console.log("min/max: " + min + "/" + max);

      min +=
        ((item.deficiency.reduction_min / 100) *
          props.costOfVacation *
          item.days) /
        props.vacationDuration;
      max +=
        ((item.deficiency.reduction_max / 100) *
          props.costOfVacation *
          item.days) /
        props.vacationDuration;

      console.log("category/min/max" + item.category + "/" + min + "/" + max);
      returnArray[item.category].category = item.category;
      console.log(
        "defi/vaca: " +
          deficiencyData[item.category] +
          "/" +
          VACATION_CATEGORIES[props.vacationCategory]
      );

      console.log(
        "limit: " +
          (props.costOfVacation *
            deficiencyData[item.category][
              VACATION_CATEGORIES[props.vacationCategory]
            ]) /
            100
      );

      if (
        deficiencyData[item.category][
          VACATION_CATEGORIES[props.vacationCategory]
        ] !== -1
      ) {
        returnArray[item.category].min = Math.min(
          min,
          (props.costOfVacation *
            deficiencyData[item.category][
              VACATION_CATEGORIES[props.vacationCategory]
            ]) /
            100
        );
        returnArray[item.category].max = Math.min(
          max,
          (props.costOfVacation *
            deficiencyData[item.category][
              VACATION_CATEGORIES[props.vacationCategory]
            ]) /
            100
        );
      } else {
        returnArray[item.category].min = min;
        returnArray[item.category].max = max;
      }
    });

    returnArray.forEach((returnValue, index) => {
      returnTotals.min += returnValue.min;
      returnTotals.max += returnValue.max;

      if (returnTotals.min > props.costOfVacation)
        returnTotals.min = props.costOfVacation;
      if (returnTotals.max > props.costOfVacation)
        returnTotals.max = props.costOfVacation;
    });

    console.log(returnArray);
    /* if (min > props.costOfVacation) min = props.costOfVacation;
    if (max > props.costOfVacation) max = props.costOfVacation;*/
    /* Math.round(min), Math.round(max)*/
    props.finalCalculationHandler(returnArray, returnTotals);
  };

  //validates the user input and if correct loads the next view
  const validateInputAndNext = () => {
    props.setValidatedStep2(false);
    if (deficienciesArray.length > 0) {
      props.setValidatedStep2(true);
      setShow(false);
      props.nextStep();
    } else {
      setAlertMessage("Es muss mindestens 1 Mangel hinzugefügt werden.");
      setShow(true);
    }

    props.trackEvent({
      category: "calculator",
      action: "click-step2-weiter-btn",
    });
  };

  //summarizes value for totals in calculator
  const summarizeTotals = () => {
    let min = 0;
    let max = 0;

    props.finalCalculation.forEach((calculation) => {
      min += calculation.min;
      max += calculation.max;
    });

    return { min: min, max: max };
  };

  //alert box to handle various erros
  function AlertDismissible() {
    return (
      <>
        <Alert show={show} variant="warning">
          <Row>
            <Col lg="10" className="d-flex justify-content-start">
              {alertMessage}
            </Col>
            <Col lg="2" className="d-flex justify-content-end">
              <i
                className="bi-x-lg d-flex justify-content-end"
                onClick={() => setShow(false)}
              ></i>
            </Col>
          </Row>
        </Alert>
      </>
    );
  }

  const switchMode = (tableActivated) => {
    setDeficiencyData(
      tableActivated ? DEFICIENCY_DATA_FRANKFURT : DEFICIENCY_DATA_ADVANCED
    );
    resetDeficiencySelect(
      0,
      tableActivated ? DEFICIENCY_DATA_FRANKFURT : DEFICIENCY_DATA_ADVANCED
    );
    setDeficienciesArray(() => []);
  };

  const handleClose = (continueVar) => {
    if (continueVar) {
      switchMode(frankfurterTabelle);
    } else {
      setFrankfurterTabelle((prevState) => !prevState);
    }
    setShowModal(false);
  };

  //return the UI
  return (
    <Card className="wizard_background">
      <Card.Body>
        <Modal show={showModal} onHide={() => handleClose(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Wechsel des Rechner-Modus</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Sie sind dabei den Rechner-Modus zu wechseln. Dadurch werden alle
            eingetragenen Mängel zurückgesetzt. Sind sie sicher?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose(false)}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={() => handleClose(true)}>
              Modus wechseln
            </Button>
          </Modal.Footer>
        </Modal>
        <Card.Title className="mb-4">Welche Mängel sind augetreten:</Card.Title>
        <Card.Text>
          <AlertDismissible />
          <Row className="mb-3">
            <Col>
              <div className="d-flex justify-content-start">
                <Form.Check
                  label="Frankfurter Tabelle Modus"
                  type="switch"
                  id="frankfurterTabelleSwitch"
                  checked={frankfurterTabelle}
                  onChange={(e) => {
                    setFrankfurterTabelle(e.target.checked);
                    setShowModal(true);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="text-start">
            <Col {...DISPLAY_SETTINGS_FOUR_COL}>
              <Form.Group className="mb-3">
                <Form.Label>Kategorie:</Form.Label>
                <Form.Select
                  aria-label="Kategorie"
                  onChange={categoryChangeHandler}
                >
                  {deficiencyData.map((item, index) => {
                    return (
                      <option
                        key={`category-${frankfurterTabelle}-${index}`}
                        value={selectedCategory === index}
                      >
                        {item.cluster}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col {...DISPLAY_SETTINGS_FOUR_COL}>
              <Form.Group className="mb-3">
                <Form.Label>Mangel:</Form.Label>
                <Form.Select
                  aria-label="Kategorie"
                  onChange={deficiencyChangeHandler}
                >
                  {selectArray.map((deficiency, index) => {
                    return (
                      <option
                        key={`deficiency-${selectedCategory}-${index}`}
                        value={selectedDeficiency === index}
                      >
                        {deficiency.deficiency}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col {...DISPLAY_SETTINGS_FOUR_COL}>
              <Form.Group className="mb-3">
                <Form.Label>Dauer (in Tage):</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={props.deficiencyDuration}
                  onChange={deficiencyDurationHandler}
                  disabled={deficiencyDurationFieldDisabled}
                />
              </Form.Group>
            </Col>
            <Col {...DISPLAY_SETTINGS_FOUR_COL}>
              <Form.Group className="mb-3">
                <Form.Label>&nbsp;</Form.Label>
                <Button
                  className="btn btn-primary btn d-flex align-items-end"
                  onClick={addDeficiency}
                >
                  Mangel hinzufügen
                </Button>
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="text-start">
              {deficienciesArray.length > 0 && <h5>Mangelliste:</h5>}
              <Table responsive>
                {deficienciesArray.length > 0 && (
                  <thead>
                    <tr key="header-head">
                      <th key="header-1">Dauer (in Tage)</th>
                      <th key="header-2">Mangel</th>
                      <th key="header-3">Von (in €)</th>
                      <th key="header-4">Bis (in €)</th>
                      <th key="header-5">Info</th>
                      <th key="header-6">Löschen</th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {deficienciesArray.map((item, index) => {
                    return (
                      <tr
                        key={`${item.deficiency.deficiency}-${item.days}-header-head`}
                      >
                        <td
                          key={`${item.deficiency.deficiency}-${item.days}-1`}
                        >
                          {item.days}
                        </td>
                        <td
                          key={`${item.deficiency.deficiency}-${item.days}-2`}
                        >
                          {item.deficiency.deficiency} (
                          {deficiencyData[item.category].cluster})
                        </td>

                        <td
                          key={`${item.deficiency.deficiency}-${item.days}-3`}
                        >
                          {Math.round(
                            ((item.deficiency.reduction_min / 100) *
                              props.costOfVacation *
                              item.days) /
                              props.vacationDuration
                          )}
                        </td>
                        <td
                          key={`${item.deficiency.deficiency}-${item.days}-4`}
                        >
                          {Math.round(
                            ((item.deficiency.reduction_max / 100) *
                              props.costOfVacation *
                              item.days) /
                              props.vacationDuration
                          )}
                        </td>

                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Popover id={`popover-positioned-right`}>
                              <Popover.Body>
                                <pre>{item.deficiency.comment}</pre>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <td
                            key={`${item.deficiency.deficiency}-${item.days}-5`}
                          >
                            <i className="bi-info-circle px-2"></i>
                          </td>
                        </OverlayTrigger>
                        <td
                          key={`${item.deficiency.deficiency}-${item.days}-6`}
                        >
                          <i
                            className="bi-trash px-2"
                            onClick={deleteDeficiencyHandler(index)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {deficienciesArray.length > 0 && (
                <div>
                  <strong>Mögliche Rückerstattung: </strong>{" "}
                  <Table responsive>
                    {props.finalCalculation.length > 0 && (
                      <thead>
                        <tr key="header-head">
                          <th key="calculation-1">Kategorie</th>
                          <th key="hecalculationader-2">Min. Erstattung</th>
                          <th key="calculation-3">Max. Erstattung</th>
                          <th key="calculation-4">Max pro Kategorie</th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {props.finalCalculation.map((calculation, index) => {
                        return (
                          <tr key={`${calculation.category}-${index}-row`}>
                            <td key={`${calculation.category}-${index}-cell-1`}>
                              {deficiencyData[calculation.category].cluster}
                            </td>
                            <td key={`${calculation.category}-${index}-cell-2`}>
                              {Math.round(calculation.min) + "€"}
                            </td>
                            <td key={`${calculation.category}-${index}-cell-3`}>
                              {Math.round(calculation.max) + "€"}
                            </td>
                            <td key={`${calculation.category}-${index}-cell-4`}>
                              {calculation.catMax === -1
                                ? "unbegrenzt"
                                : Math.round(calculation.catMax) + "€"}
                            </td>
                          </tr>
                        );
                      })}
                      <tr key="total-row">
                        <td key="total-cell-1">
                          <strong>Total:</strong>
                        </td>
                        <td key="total-cell-2">
                          <strong>{Math.round(props.totals.min)}€</strong>
                        </td>
                        <td key="total-cell-3">
                          <strong>{Math.round(props.totals.max)}€</strong>
                        </td>
                        <td key="total-cell-4">
                          <strong>{props.costOfVacation}€</strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <p>
                    {props.costOfVacation === props.finalCalculation.minCalc &&
                      " (Maximale Erstattung für Reise erreicht. Fügen sie dennoch alle Mängel hinzu, um die Chance auf Rückerstattung zu erhöhen.)"}
                  </p>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-start" {...DISPLAY_SETTINGS_TWO_COL}>
              <button
                className="btn btn-primary btn"
                onClick={() => {
                  props.previousStep();
                  props.trackEvent({
                    category: "calculator",
                    action: "click-step2-zurück-btn",
                  });
                }}
              >
                Zurück
              </button>
            </Col>
            <Col
              className="text-start text-md-end pt-1"
              {...DISPLAY_SETTINGS_TWO_COL}
            >
              <button
                className="btn btn-primary btn"
                onClick={validateInputAndNext}
              >
                So geht es weiter
              </button>
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

////Component of third step of step wizard. View to show calculated deficiency costs and to let user choose next steps
const Abschluss = (props) => {
  return (
    <Card className="wizard_background">
      <Card.Body>
        <Card.Title className="mb-4">
          Die Berechnung ist abgeschlossen...
        </Card.Title>
        <Card.Text>
          <Row
            className="d-flex justify-content-center text-start"
            xs={1}
            md={2}
            lg={3}
          >
            <Col className="d-flex justify-content-center mb-4">
              {" "}
              <Card className="onsite_card">
                <Card.Body>
                  <Card.Title className="mt-3">
                    <p className="large_number">01</p>
                    <h2>Gratulation zur mögl. Rückerstattung</h2>
                  </Card.Title>
                  <Card.Text>
                    <p>
                    {props.totals.min === props.totals.max ? "Ihre mögliche Rückerstattung ist " + props.totals.min + "€"  : "Ihre mögliche Rückerstattung liegt zwischen " + props.totals.min + "€ und " + props.totals.max + "€"}      
                    </p>
                    Ansprüche können sich stark Unterscheiden und sollten am
                    besten durch einen Anwalt geprüft werden.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>{" "}
            <Col className="d-flex justify-content-center mb-4">
              <Card className="onsite_card">
                <Card.Body>
                  <Card.Title className="mt-3">
                    <p className="large_number">02a</p>
                    <h2>Schreibe direkt deinem Reiseveranstalter</h2>
                  </Card.Title>
                  <Card.Text>
                    Setze ein Mängelschreiben auf und kontaktiere deinen
                    Reiseveranstalter. In Zukunft wirst du hier auch die
                    Möglichkeit haben das Anschreiben automatisch zu generieren.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>{" "}
            <Col className="d-flex justify-content-center mb-4">
              <Card className="onsite_card">
                <Card.Body>
                  <Card.Title className="mt-3">
                    <p className="large_number">02b</p>
                    <h2>Hole dir Unterstützung durch einen Anwalt</h2>
                  </Card.Title>
                  <Card.Text>
                    Deine Wahrscheinlichkeit für eine Rückerstattung erhöhrt
                    sich indem du einen Anwalt beauftragst. In Zukunft werden
                    wir hier spezialisierte Anwälte für Reiserecht vermittelt.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-start">
              <button
                className="btn btn-primary btn"
                onClick={() => {
                  props.previousStep();
                  props.trackEvent({
                    category: "calculator",
                    action: "click-step3-zurück-btn",
                  });
                }}
              >
                Zurück
              </button>
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

//Component of fourth step of step wizard
const Userdata = (props) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title className="mb-4">Was sind deine Daten:</Card.Title>
        <Card.Text>
          <Row className="text-start">
            <Col>
              <Form.Check
                inline
                label="Herr"
                name="mr"
                type="radio"
                id="inline-radio-1"
              />
              <Form.Check
                inline
                label="Frau"
                name="mrs"
                type="radio"
                id="inline-radio-2"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Vorname" key="surname" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Nachname" key="name" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control type="tel" placeholder="Telefon" key="phone" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control type="mail" placeholder="E-Mail" key="mail" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  type="mail"
                  placeholder="E-Mail wiederholen"
                  key="mail2"
                />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-start">
              <strong>Adresse:</strong>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Straße" key="street" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  type="number"
                  placeholder="Postleitzahl"
                  key="postal"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Stadt" key="city" />{" "}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Land" key="country" />
              </Form.Group>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
          <Row>
            <Col className="text-start">
              <button
                className="btn btn-primary btn"
                onClick={props.previousStep}
              >
                Zurück
              </button>
            </Col>
            <Col className="text-end">
              <button className="btn btn-primary btn">Abschicken</button>
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

//main component to handle step wizard
const Calculator = (props) => {
  //hooks that need to be saved in this main component
  const [vacationDuration, setVacationDuration] = React.useState(1);
  const [finalCalculation, setFinalCalculation] = React.useState([]);
  const [totals, setTotals] = React.useState({ min: 0, max: 0 });

  //hooks from step1
  const [validatedStep1, setValidatedStep1] = React.useState(false);
  const [travelAgency, setTravelAgency] = React.useState("");
  const [costOfVacation, setCostOfVacation] = React.useState(0);
  const [adultsAmount, setAdultsAmount] = React.useState(1);
  const [vacationCategory, setVacationCategory] = React.useState(0);

  //hooks from step2
  const [validatedStep2, setValidatedStep2] = React.useState(false);
  const [deficiencyDuration, setDeficiencyDuration] = React.useState(1);

  const changeCostOfVacationHandler = (event) => {
    setValidatedStep1(false);
    setCostOfVacation(event.target.value);
  };

  const setVacationDurationHandler = (beginDate, endDate) => {
    setVacationDuration((endDate - beginDate) / 24 / 60 / 60 / 1000);
    setDeficiencyDuration((endDate - beginDate) / 24 / 60 / 60 / 1000);
  };

  const finalCalculationHandler = (returnArray, returnTotals) => {
    setFinalCalculation(returnArray);
    setTotals(returnTotals);
  };

  return (
    <Container className="p-0" fluid>
      <Helmet>
        <title>Online-Rechner</title>
        <meta name="description" content="Online-Rechner" />
      </Helmet>
      <header className="masthead masthead_calculator d-flex align-items-center">
        <div className="container px-4 px-lg-5 text-center">
          <h2 className="mb-4">Schätze deine Entschädigung</h2>
          <StepWizard
            className="wizard_class"
            isHashEnabled
            nav={
              <Nav
                validatedStep1={validatedStep1}
                validatedStep2={validatedStep2}
                trackEvent={props.trackEvent}
              />
            }
          >
            <BasicData
              costOfVacation={costOfVacation}
              changeCostOfVacationHandler={changeCostOfVacationHandler}
              setVacationDurationHandler={setVacationDurationHandler}
              validatedStep1={validatedStep1}
              setValidatedStep1={setValidatedStep1}
              travelAgency={travelAgency}
              setTravelAgency={setTravelAgency}
              adultsAmount={adultsAmount}
              setAdultsAmount={setAdultsAmount}
              vacationCategory={vacationCategory}
              setVacationCategory={setVacationCategory}
              trackEvent={props.trackEvent}
            />
            <Reisedaten
              costOfVacation={costOfVacation}
              vacationDuration={vacationDuration}
              finalCalculationHandler={finalCalculationHandler}
              finalCalculation={finalCalculation}
              totals={totals}
              validatedStep2={validatedStep2}
              setValidatedStep2={setValidatedStep2}
              deficiencyDuration={deficiencyDuration}
              setDeficiencyDuration={setDeficiencyDuration}
              vacationCategory={vacationCategory}
              trackEvent={props.trackEvent}
            />
            <Abschluss
              finalCalculation={finalCalculation}
              totals={totals}
              trackEvent={props.trackEvent}
            />
            {/*<Userdata />*/}
          </StepWizard>
          <p className="mt-4">
            Sie benötigen mehr Informationen? Kein Problem.{" "}
            <a href="/#quickinfo">Klicke hier.</a>
          </p>
        </div>
      </header>
    </Container>
  );
};

export default Calculator;
