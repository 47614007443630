import React from "react";
import {
  Container,
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Badge
} from "react-bootstrap";


const Home = () => {
  const [activeId, setActiveId] = React.useState("");

  const openAccordionElement = (element) => {
    setActiveId("" + element);
  };


  return (
    <Container fluid className="p-0">
      <header className="masthead">
        <div className="container px-4 text-center">
          <Row className="d-flex justify-content-center">
            <Col xs="10" md="8" lg="4"  className="text-primary page_title d-flex justify-content-center">
                  <p className="p-3"><img className="w-100" src="../assets/img/logo2.png" alt="Travelrights"/></p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col sm="12" className="d-flex justify-content-center p-3">
              <h1>Der Online-Rechner zum Reiserecht</h1>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="d-none d-sm-block d-flex justify-content-center">
              <p>
                Reise-Ärger? Wir berechnen Ihren Anspruch! Sofort! Kostenlos!
                Holen Sie Ihr Geld zurück!
              </p>
            </Col>
          </Row>
          <Row className="mt-3 mb-4">
            <Col
              xs="12"
              sm="6"
              className="d-flex justify-content-center justify-content-sm-end mb-3"
            >
              <a
                id="guide_button" className="btn btn-xl w-75"
                href="/massnahmen-vor-ort"
              >
                Noch vor Ort? Das ist zu tun.
              </a>
            </Col>
            <Col
              xs="12"
              sm="6"
              className="d-flex justify-content-center justify-content-sm-start mb-3"
            >
              <a className="btn btn-primary btn-xl w-75" href="/online-rechner">
                Entschädigung kostenlos berechnen
              </a>
            </Col>
          </Row>
          <p>
            Sie wollen mehr Informationen:{" "}
            <a className="link_no_underlining" href="#quickinfo">
              So funktioniert's
            </a>
          </p>
        </div>
      </header>
      {/* Quick Info*/}
      <section
        className="content-section bg-primary text-white text-center"
        id="quickinfo"
      >
        <div className="container px-4 px-lg-5">
          <div className="content-section-heading">
            <h3 className="text-secondary mb-0">Quick Info</h3>
            <h1 className="mb-5">So funktioniert's</h1>
          </div>
          <div className="row gx-4 gx-lg-5">
            <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-note"></i>
              </span>
              <h4>
                <strong>Vor Ort reklamieren und Protokoll führen</strong>
              </h4>
              <p className="text-faded mb-0">
                Wenn Sie Geld zurück bekommen wollen, müssen Sie vor Ort ein
                paar Dinge beachten. Nutzen Sie unseren kostenlosen{" "}
                <a className="text-faded " href="/massnahmen-vor-ort">
                  Vor-Ort Ratgeber!
                </a>{" "}
              </p>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-screen-desktop"></i>
              </span>
              <h4>
                <strong>Mängel eingeben</strong>
              </h4>
              <p className="text-faded mb-0">
                Wählen Sie einfach und benutzerfreundlich aus den im Reiserecht
                anerkannten Mängeln. Topaktuell aus der kürzlich reformierten
                Gesetzgebund und der aktuellen Rechtsprechung. <br />
                <a className="text-faded" href="#faq-law">
                  Erfahren Sie mehr!
                </a>
              </p>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 mb-md-0">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-calculator"></i>
              </span>
              <h4>
                <strong>Erstattung berechnen</strong>
              </h4>
              <p className="text-faded mb-0">
                Unser {" "}
                  <a href="/online-rechner" className="text-faded">
                    Online-Kalkulator
                  </a>{" "} berechnet sofort und kostenlos Ihre
                möglichen Erstattungsansprüche! Ihr Recht gilt 1-2 Jahre
                rückwirkend, abhängig von der Reise-AGB Ihres Reiseanbieters.
                <br />
                <a className="text-faded" href="#faq-calculation">
                  So wird berechnet.
                </a>
              </p>
            </div>
            <div className="col-lg-3 col-md-6">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-credit-card"></i>
              </span>
              <h4>
                <strong>Entschädigung erhalten</strong>
              </h4>
              <p className="text-faded mb-2">
                Kontaktieren Sie Ihren Reiseanbieter und holen Sie Ihr Geld
                zurück!
              </p>
              <p className="text-faded mb-0">
                In Kürze werden Sie hier weitere Services finden: Einen
                Generator für Anspruchsschreiben und die Möglichkeit, Ihre
                Ansprüche kompetent von erfahrenen Reiserechtlern durchsetzen zu
                lassen!
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Services*/}
      <section className="content-section" id="services">
        <div className="container px-4 px-lg-5">
          <div className="content-section-heading text-center">
            <h1 className="mb-5">Unsere Services</h1>
            <h3 className="text-dark mb-4">
              Travelrights unterstützt Sie, wenn Sie in Ihrem Pauschalurlaub
              nicht die versprochenen Leistungen erhalten.
            </h3>
          </div>
          <div className="row gx-3 gy-4">
            <div className="col-xxl-3 col-lg-6 d-flex justify-content-center">
              <Card border="dark w-100 h-100" style={{ width: "18rem" }}>
                <Card.Img variant="top" src="../assets/img/guide.jpg" />

                <Card.Body>
                  <Card.Title>
                    <h1 className="header_card">
                      Kostenloser Vor-Ort Ratgeber
                    </h1>
                  </Card.Title>
                  <Card.Text>
                    Oft verläuft der Urlaub anders als geplant - Sie bekommmen
                    nicht das, wofür Sie bezahlt haben. Typische Beispiele: Ihr
                    Flug wird kurzfristig umgebucht. Ihr Hotel ist überbucht.
                    Ihre Toilette funktioniert nicht richtig. Der versprochene
                    Pool wird umgebaut, usw. In diesem Fall steht Ihnen
                    Schadensersatz zu. Um diesen zu bekommen, müssen Sie vor Ort
                    aktiv werden. Wir unterstützen Sie dabei mit unserem
                    kostenlosen Vor-Ort-Ratgeber.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-xxl-3 col-lg-6  d-flex justify-content-center">
              <Card border="dark w-100 h-100" style={{ width: "18rem" }}>
                <Card.Img variant="top" src="../assets/img/calculator.jpg" />
                <Card.Body>
                  <Card.Title>
                    <h1 className="header_card">
                      Kostenlose Berechnung Ihres Erstattungsanspruchs
                    </h1>
                  </Card.Title>
                  <Card.Text>
                    Unser {" "}
                  <a href="/online-rechner" className="link_no_underlining">
                    Online-Kalkulator
                  </a>{" "} rechnet für Sie aus, wieviel Geld
                    Sie wahrscheinlich zurück erhalten. Kostenlos, sofort,
                    benutzerfreundlich. Und vor allem basierend auf der
                    aktuellen Gesetzeslage. Wussten Sie, dass 2018 das
                    Reiserecht reformiert wurde? Unser Rechner basiert auf der
                    {" "}
                  <a href="/frankfurter-tabelle" className="link_no_underlining">
                    Frankfurter Tabelle
                  </a>{" "}, berücksichtigt aber auch die aktuelle
                    Rechtsprechung!
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-xxl-3 col-lg-6  d-flex justify-content-center">
              <Card border="dark w-100 h-100" style={{ width: "18rem" }}>
                <Card.Img variant="top" src="../assets/img/letter.jpg" />
                <Card.Body>
                  <Card.Title>
                    <h1 className="header_card">
                    Generierung Ihres Anspruchsschreibens <Badge pill bg="primary">In Kürze</Badge>
                    </h1>
                  </Card.Title>
                  <Card.Text>
                    Wir arbeiten an einem weiteren Service: Bald können Sie aus
                    Ihren eingegebenen Mängeln ein individuelles
                    Anspruchsschreiben erzeugen und sich damit an Ihren
                    Reiseanbieter wenden. Genial, oder?
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-xxl-3 col-lg-6  d-flex justify-content-center">
              <Card border="dark w-100 h-100" style={{ width: "18rem" }}>
                <Card.Img variant="top" src="../assets/img/lawyer.jpg" />
                <Card.Body>
                  <Card.Title>
                    <h1 className="header_card">
                     Reiserechtsexperten kümmern sich um Ihre Rechte <Badge pill bg="primary">Geplant</Badge>
                    </h1>
                  </Card.Title>
                  <Card.Text>
                    Oft versuchen Reiseanbieter Kundenbeschwerden durch
                    Gutscheine abzubügeln. Das erfordert Durchhaltevermögen und
                    kostet Energie! Wir bauen derzeit ein Netzwerk an
                    Reiserechtsexperten auf, die Ihnen dann für die Durchsetzung
                    Ihrer Ansprüche zur Verfügung stehen!
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* Callout*/}
      <section className="callout" id="overview">
        <div className="container px-4 px-lg-5 text-center">
          <h1 className="mx-auto mb-5">Reiserecht - F.A.Q.</h1>
          <Accordion id="accordion" align="left" activeKey={activeId} onSelect={e => openAccordionElement(e)}>
            <Accordion.Item eventKey="0" className="accordion_class">
              <Accordion.Header>Welche Rechte habe ich?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Sind Ihnen zugesagte, von Ihnen gebuchte Leistungen einer
                  Pauschalreise mangelhaft, so haben Sie mit hoher
                  Wahrscheinlichkeit{" "}
                  <a
                    href="#calculation-details"
                    className="link_no_underlining"
                  >
                    Anspruch auf Entschädigung
                  </a>
                  , also einen finanziellen Ausgleich in Form eines geminderten
                  Reisepreises. In besonders harten Fällen können Sie die Reise
                  auch kündigen.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="accordion_class">
              <Accordion.Header>
                Woran orientieren Sich die Reiseanbieter (und Gerichte)?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Gerichte und Reiseveranstalter orientieren sich bei der{" "}
                  <a
                    href="#calculation-details"
                    className="link_no_underlining"
                  >
                    Bestimmung der Minderungsansprüchen
                  </a>{" "}
                  in der Regel an existierenden Urteilen. Dabei werden
                  verschiedene Zusammenfassungen von Gerichtsentscheidungen
                  herangezogen, wie die ältere 
                    {" "}
                  <a href="/frankfurter-tabelle" className="link_no_underlining">
                    Frankfurter Tabelle
                  </a>{" "}, die neuere
                  Kemptener Liste und die Liste des ADAC. Diese Listen
                  ermöglichen eine Orientierung, welche Reisepreisminderung für
                  welche Minderleistung angemessen ist.{" "}
                </p>
                <p>
                  Wichtig! Es besteht kein Rechtsanspruch auf die dort
                  aufgeführten Erstattungsanteile. Bei der Bewertung wird immer
                  der individuelle Einzelfall betrachtet. Unsere
                  Reiserecht-Experten (Partner-Anwälte) analysieren gerne Ihren
                  Fall und helfen Ihnen zu Ihrem Recht!
                </p>
                <p>
                  Unser{" "}
                  <a href="/online-rechner" className="link_no_underlining">
                    Entschädigungsrechner
                  </a>{" "}
                  hilft Ihnen, eine angemessene Entschädigung basierend auf den
                  wichtigsten Gerichtsurteilen (basierend auf der 
                    {" "}
                  <a href="/frankfurter-tabelle" className="link_no_underlining">
                    Frankfurter Tabelle
                  </a>{" "} und weiteren wichtigen Urteilen) zu bestimmen (
                  <a
                    href="#calculation-details"
                    className="link_no_underlining"
                  >
                    Mehr Information
                  </a>
                  ).
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item id="test" eventKey="2" className="accordion_class">
              <Accordion.Header>
                Was muss ich beachten, wenn ich Geld zurück fordern möchte?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Es gibt einige Dinge zu beachten, die wichtigsten sind
                  sicherlich folgende:
                </p>
                <ul>
                  <li>
                    Um Geld zurück zu erhalten, müssen Sie bereits vor Ort tätig
                    werden, und beispielsweise die Mängel anzeigen (
                    <a
                      href="/massnahmen-vor-ort"
                      className="link_no_underlining"
                    >
                      Was muss ich tun?
                    </a>
                    ).
                  </li>
                  <li>
                    <p>
                      Nach der Rückkehr sind die Minderungsansprüche beim
                      Reiseveranstalter geltend zu machen. Die Praxis zeigt,
                      dass Unternehmen oft versuchen, die betroffenen Kunden mit
                      einem Gutschein abzuspeisen oder manchmal gar nicht
                      reagieren. Das müssen Sie nicht hinnehmen: eine
                      anwaltliche Intervention führt normalerweise deutlich
                      schneller zum Erfolg – Experten (wie unsere
                      Partner-Anwälte) haben Erfahrung darin, Ihre Ansprüche
                      bestmöglich durchzusetzen – außergerichtlich, falls nötig
                      auch vor Gericht. Lassen Sie sich von unseren
                      Partneranwälten beraten!
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="3"
              className="accordion_class"
              id="faq-calculation"
            >
              <Accordion.Header>
                Wie funktioniert Euer Online Rechner? Wie wird mein Anspruch
                berechnet?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  <a href="/online-rechner" className="link_no_underlining">
                    Unser Rechner
                  </a>{" "}
                  schätzt automatisiert Ihre Ansprüche aufgrund von
                  Gerichtsentscheidungen zum Reiserecht ab. Dazu werden
                  verschiedene Quellen verwendet welche entweder die Spielräume
                  der Minderungsquoten der Entscheidungen kombinieren oder die
                  Reiserecht-Urteile nach Kategorien ordnen.
                </p>
                <p>
                  Bitte beachten Sie:
                  <ul>
                    <li>
                      <b>Geringfügige Beeinträchtigungen</b>{" "}
                      {/* (Tooltip: z.B. kurzzeitige Stromunterbrechung)*/}{" "}
                      werden als <b>Unannehmlichkeit</b> gewertet – als
                      Betroffener müssen Sie diese hinnehmen, ohne auf eine
                      Minderung des Reisepreises pochen zu können.
                    </li>
                    <li>
                      Die <b>Minderungsquoten</b> beziehen sich i.d.R. auf den{" "}
                      <b>Tagesreisepreis</b> für die betroffenen Tage.
                      Berechnungsgrundlage ist also immer die Gesamtsumme der
                      Pauschalreise und die Reisedauer.
                    </li>
                    <li>
                      Mängel beziehen sich auf <b>zugesicherte Bestandteile</b>
                      des Pauschreise-Vertrages bzw. auf{" "}
                      <b>schuldhaftes Handeln</b> des Reiseveranstalters. Die
                      Vertragsbestandteile ergeben sich u.a. aus dem
                      Reiseprospekt, der Reisebestätigung, dem Reisecharakter
                      und getroffenen Zusatzvereinbarungen.
                    </li>
                    <li>
                      Liegen <b>mehrere Mängel</b> vor, so werden die
                      Minderungsquoten <b>addiert</b>.
                    </li>
                    <li>
                      Bei <b>erheblichen Beeinträchtigungen</b> (in der Regel ab
                      über 50% Minderung pro Tag) besteht ein Anspruch auf{" "}
                      <b>Schadensersatz wegen entgangener Urlaubsfreuden</b>.
                    </li>
                    <li>
                      Bei gravierenden Mängeln oder einer schuldhaften
                      Vereitelung der Reise durch den Reiseanbieter kommt auch
                      eine <b>Kündigung</b> nach § 651 L BGB in Betracht
                      (Reiseabbruch / Nicht-Antreten der Reise).
                    </li>
                  </ul>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="accordion_class">
              <Accordion.Header>
                Gibt es noch weitergehende Schadensersatzansprüche?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  In die Einzelfallbetrachtung sind Sonderfälle zu
                  berücksichtigen, die weitere Schadensersatzansprüche nach sich
                  ziehen können:
                </p>
                <ul>
                  <li>
                    Bei <b>Flugverspätung</b> ab 3h haben Betroffene IMMER
                    Anspruch auf Entschädigung, unabhängig von anderen
                    Erstattungen.
                  </li>
                  <li>
                    Bei <b>Gepäckverspätungen (oder -verlusten)</b> haben Sie
                    neben dem Anspruch von Preisminderung von 20 – 25% pro
                    Reisetag weitere Schadensersatzansprüche (z.B. für die
                    Kosten von Ersatzprodukten)
                  </li>
                  <li>
                    Sind <b>Verletzungen</b> z.B. durch ungesichertes
                    Hotelgelänge entstanden, so kommen auch
                    Schmerzensgeldforderungen in Betracht.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="5"
              className="accordion_class"
              id="faq-law"
            >
              <Accordion.Header>
                Wie ist das Reiserecht in Gesetzgebung &amp; Rechtsprechung
                vertreten?
              </Accordion.Header>
              <Accordion.Body>
                <h5>
                  <strong>BGB §§651</strong>
                </h5>
                <p>
                  Hier findet sich der Pauschalreisevertrag im Bürgerlicheren
                  Gesetzbuch. Grundlegende Reformation 2018 mit der Umsetzung
                  der EU-Richtlinie „Richtlinie (EU) 2015/2302 über
                  Pauschalreisen und verbundene Reiseleistungen“ in nationales
                  Recht. Rechte des Reisenden wurden erweitert, Ansprüche aus
                  dem Reisevertrag verjähren nun erst nach 1 oder 2 Jahren (je
                  nach Reise-AGB des Veranstalters).
                </p>
                <h5>
                  <strong>Frankfurter Tabelle</strong>
                </h5>
                <p>
                  Mitte der 1980er-Jahre vom Frankfurter Landgerichts
                  eingeführt. Die Reiserecht-Tabelle wird zur Abschätzung der
                  Höhe von Gewährleistungsansprüchen &amp; Preiminderungen bei
                  Pauschalreisen herangezogen.
                </p>
                <p>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover id="info_frankfurter_tabelle">
                        <Popover.Body>
                          Hinweis: Es existiert kein offizieller Link auf die
                          Frankfurter Tabelle (z.B. beim LG Frankfurt). Dafür
                          kursieren diverse (und teilweise grob fehlerhafte
                          Varianten, teilweise ohne die Aktualisierungen aus
                          1994). Wir haben uns entschieden, die 2012 durch die
                          Kanzlei Alsdorf geprüfte Version zu verlinken. Diese
                          findet auch in unserem Kalkulator Anwendung.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    
                    
                  <a href="/frankfurter-tabelle" className="link_no_underlining">
                    Frankfurter Tabelle
                  </a> 
                  </OverlayTrigger>
                  {" "} (Basistabelle 1985 mit den Aktualisierungen aus 1994, 2012
                  geprüft){" "}
                
                </p>
                <h5>
                  <strong>Kemptener Reisemängeltabelle</strong>
                </h5>
                <p>
                  Die Kemptener Reisemängeltabelle enthält neuere
                  Gerichtsurteile ab den 2000er Jahren und wird von manchen
                  Gerichten als Ersatz oder Ergänzung der Frankfurter Tabelle
                  angesehen. Die Tabelle wird vom erfahrenen Reiserechtler Prof.
                  Dr. Ernst Führich gepflegt und regelmäßig veröffentlicht.
                </p>
                <p>
                  <a
                    href="https://reiserechtfuehrich.com/2022/03/05/fuhrichs-kemptener-reisemangeltabelle-aktualisiert-mit-stand-marz-2022/"
                    className="link_no_underlining"
                  >
                    Kemptener Reisemängeltabelle
                  </a>{" "}
                  (März 2022)
                </p>
                <h5>
                  <strong>ADAC Reisemängelliste</strong>
                </h5>
                <p>
                  Ähnlich wie die Kemptener Reisemängeltabelle bietet die
                  Reisepreisminderungstabelle des ADAC einen Überblick über
                  Reisemängel-Urteile und entsprechende Minderungsquoten.
                </p>
                <p>
                  <a
                    href="https://www.adac.de/-/media/adac/pdf/jze/reisepreisminderungstabelle.pdf?la=de-de"
                    className="link_no_underlining"
                  >
                    ADAC Reisepreisminderungstabeller
                  </a>{" "}
                  (aktueller Stand)
                </p>
              </Accordion.Body>
            </Accordion.Item>

            {/* <Accordion.Item eventKey="6">
              <Accordion.Header>Accordion Item #2</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </div>
      </section>

      {/* About*/}
      {/*       <section className="content-section bg-light" id="about">
        <div className="container px-4 px-lg-5 text-center">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-10">
              <h2>
                Wir setzen ihre Rechte bei Pauschal- und Urlaubsreisen durch!
              </h2>
              <p className="lead mb-5">
                Entschädigungen durch die Frankfurter Tabelle
              </p>
              <a className="btn btn-dark btn-xl" href="#services">
                Das bieten wir an
              </a>
            </div>
          </div>
        </div>
      </section> */}

      {/* Callout*/}
      {/* <section className="callout">
        <div className="container px-4 px-lg-5 text-center">
          <h2 className="mx-auto mb-5">Diese Services bieten wir!</h2>
          Cards mit Services werden hier eingefügt.
        </div>
      </section> */}

      {/* Call to Action*/}
      {/*  <section className="content-section bg-primary text-white">
        <div className="container px-4 px-lg-5 text-center">
          <h2 className="mb-4">
            The buttons below are impossible to resist...
          </h2>
          <a className="btn btn-xl btn-light me-4" href="#!">
            Click Me!
          </a>
          <a className="btn btn-xl btn-dark" href="#!">
            Look at Me!
          </a>
        </div>
      </section> */}
    </Container>
  );
};

export default Home;
