import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import yn from 'yn';

/*
const instance = createInstance({
  urlBase: "https://bloede-idee.de/info/",
  siteId: 2,
  userId: "undefined", // optional, default value: `undefined`.
  trackerUrl: "https://bloede-idee.de/info/matomo.php", // optional, default value: `${urlBase}matomo.php`
  srcUrl: "https://bloede-idee.de/info/matomo.js", // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: false,
    setRequestMethod: "POST",
  },
});*/
/*
{console.log(
  process.env.REACT_APP_URLBASE +
    ":" +
    process.env.REACT_APP_SITEID +
    ":" +
    process.env.REACT_APP_USERID +
    ":" +
    process.env.REACT_APP_TRACKLERURL +
    ":" +
    process.env.REACT_APP_SRCURL +
    ":" +
    process.env.REACT_APP_DISABLED + ":" +
    process.env.REACT_APP_HEARTBEAT_ACTIVE + ":" + 
    process.env.REACT_APP_HEARTBEAT_SECONDS + ":" + 
    process.env.REACT_APP_LINKTRACKING + ":" + 
    process.env.REACT_APP_CONFIGURATIONS_DISABLE_COOKIES + ":" + 
    process.env.REACT_APP_CONFIGURATIONS_SETSECURECOOKIE + ":" + 
    process.env.REACT_APP_CONFIGURATIONS_SETREQUESTMETHOD
)}*/
const instance = createInstance({
  urlBase: process.env.REACT_APP_URLBASE,
  siteId: process.env.REACT_APP_SITEID,
  userId: process.env.REACT_APP_USERID, // optional, default value: `undefined`.
  trackerUrl: process.env.REACT_APP_TRACKLERURL, // optional, default value: `${urlBase}matomo.php`
  srcUrl: process.env.REACT_APP_SRCURL, // optional, default value: `${urlBase}matomo.js`
  disabled: yn(process.env.REACT_APP_DISABLED), // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: yn(process.env.REACT_APP_HEARTBEAT_ACTIVE), // optional, default value: true
    seconds: process.env.REACT_APP_HEARTBEAT_SECONDS, // optional, default value: `15
  },
  linkTracking: yn(process.env.REACT_APP_LINKTRACKING), // optional, default value: true
  configurations: {
    // optional, default value: {} // any valid matomo configuration, all below are optional
    disableCookies: yn(process.env.REACT_APP_CONFIGURATIONS_DISABLE_COOKIES),
    setSecureCookie: yn(process.env.REACT_APP_CONFIGURATIONS_SETSECURECOOKIE),
    setRequestMethod: process.env.REACT_APP_CONFIGURATIONS_SETREQUESTMETHOD,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <App />
    </MatomoProvider>
    ,
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
