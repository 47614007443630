import { ProgressBar } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

const Nav = (props) => {
  return (
    <div>
      {/*<Row className="d-flex justify-content-center">
        <Col xs="7">
          <ProgressBar className="progressbar-calculator" now={60} />
        </Col>
  </Row>*/}
      <Row className="calc-row d-flex justify-content-center mb-1">
        <Col xs="3" md="2" className="m-0 p-0 ">
          <div className="calc_nav">
            <hr
              className={`d-none d-lg-inline-block divider ${
                props.currentStep === 1 ? "divideractive" : ""
              }`}
              style={{ width: "32%", display: "inline-block" }}
            />
            <span
              style={{ width: "30%", display: "inline-block" }}
              key="step-1"
              onClick={() => props.goToStep(1)}
              className={`mx-1 dot ${
                props.currentStep === 1 ? "dotactive" : ""
              }`}
            >
              <i className="fa-solid fa-umbrella-beach"></i>
            </span>
            <hr
              className={`d-none d-lg-inline-block divider ${
                props.currentStep === 1 ? "divideractive" : ""
              }`}
              style={{ width: "32%", display: "inline-block" }}
            />
          </div>
        </Col>

        <Col xs="3" md="2" className="m-0 p-0">
          <div className="calc_nav">
            <hr
              className={`d-none d-lg-inline-block divider ${
                props.currentStep === 2 ? "divideractive" : ""
              } ${!props.validatedStep1 ? "dividerdeactivated" : ""}`}
              style={{ width: "32%", display: "inline-block" }}
            />
            <span
              style={{ width: "30%", display: "inline-block" }}
              key="step-2"
              onClick={() => {
                props.validatedStep1 && props.goToStep(2);
              }}
              className={`mx-1 dot ${
                props.currentStep === 2 ? "dotactive" : ""
              } ${!props.validatedStep1 ? "dotdeactivated" : ""}`}
            >
              <i className="fa-solid fa-bolt"></i>
            </span>
            <hr
              className={`d-none d-lg-inline-block divider ${
                props.currentStep === 2 ? "divideractive" : ""
              } ${!props.validatedStep1 ? "dividerdeactivated" : ""}`}
              style={{ width: "32%", display: "inline-block" }}
            />
          </div>
        </Col>

        <Col xs="3" md="2" className="m-0 p-0">
          <div className="calc_nav">
            <hr
              className={`d-none d-lg-inline-block divider ${
                props.currentStep === 3 ? "divideractive" : ""
              } ${
                !(props.validatedStep1 && props.validatedStep2)
                  ? "dividerdeactivated"
                  : ""
              }`}
              style={{ width: "32%", display: "inline-block" }}
            />
            <span
              style={{ width: "30%", display: "inline-block" }}
              key="step-3"
              onClick={() => {
                props.validatedStep1 &&
                  props.validatedStep2 &&
                  props.goToStep(3);
              }}
              className={`mx-1 dot ${
                props.currentStep === 3 ? "dotactive" : ""
              } ${
                !(props.validatedStep1 && props.validatedStep2)
                  ? "dotdeactivated"
                  : ""
              }`}
            >
              <i className="fa-solid fa-sack-dollar"></i>
            </span>
            <hr
              className={`d-none d-lg-inline-block divider ${
                props.currentStep === 3 ? "divideractive" : ""
              } ${
                !(props.validatedStep1 && props.validatedStep2)
                  ? "dividerdeactivated"
                  : ""
              }`}
              style={{ width: "32%", display: "inline-block" }}
            />
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-1">
        <Col xs="3" md="2">
          <p
            className={`d-none d-md-block divider ${
              props.currentStep === 1 ? "divideractive" : ""
            }`}
            style={{ width: "100%", display: "inline-block" }}
          >
            Urlaubsdaten
          </p>
        </Col>
        <Col xs="3" md="2">
          <p
            className={`d-none d-md-block divider ${
              props.currentStep === 2 ? "divideractive" : ""
            } ${!props.validatedStep1 ? "dividerdeactivated" : ""}`}
            style={{ width: "100%", display: "inline-block" }}
          >
            Mängel
          </p>
        </Col>
        <Col xs="3" md="2">
          <p
            className={`d-none d-md-block divider ${
              props.currentStep === 3 ? "divideractive" : ""
            } ${
              !(props.validatedStep1 && props.validatedStep2)
                ? "dividerdeactivated"
                : ""
            }`}
            style={{ width: "100%", display: "inline-block" }}
          >
            Erstattung
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default Nav;
