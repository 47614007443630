import React from 'react';
import Header from "./Components/header";
import Home from "./Pages/home";
import Calculator from "./Pages/calculator";
import FrankfurterTabelle from "./Pages/frankfurtertabelle";
import LiabilityStatement from "./Pages/liability";
import Onsite from "./Pages/onsite";
import Imprint from "./Pages/imprint";
import Dataprotection from "./Pages/dataprotection";
import Footer from "./Components/footer";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import "./App.css";

function App() {
  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Header />
        <BrowserRouter>
          <Routes >
            <Route path="/" element={<Home />} />
            <Route path="/online-rechner" element={<Calculator trackEvent={trackEvent} />} />
            <Route
              path="/frankfurter-tabelle"
              element={<FrankfurterTabelle />}
            />
            <Route path="/massnahmen-vor-ort" element={<Onsite />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/datenschutz" element={<Dataprotection />} />
            <Route
              path="/haftungsausschluss"
              element={<LiabilityStatement />}
            />
            {/* <Route path="/agb" element={<AGBS/>}/>*/}
          </Routes>
        </BrowserRouter>

        <Footer />
      </HelmetProvider>
    </div>
  );
}

export default App;
